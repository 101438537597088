import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthentificationService } from '../../services/authentification/authentification.service';
import { Jwt } from '../../models/jwt.model';
import { Scope } from '../../models/scope.model';
import { Organisme } from 'src/app/data/intervenant/models/organisme.model';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { Router } from '@angular/router';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';


interface Result {
  organisme: Organisme;
  jwt: Jwt;
}

@Component({
  selector: 'app-choix-organisme',
  templateUrl: './choix-organisme.component.html',
  styleUrls: ['./choix-organisme.component.scss']
})
export class ChoixOrganismeComponent implements OnInit {
  @Input()
  idDomaine: number;

  @Output()
  onSelectOrganisme = new EventEmitter<Result>();

  formGroup: FormGroup;
  show = false;

  organismes: Organisme[];

  get organisme() { return this.formGroup.get('organisme'); }

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthentificationService,
    private readonly organismesService: OrganismesService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.organismesService.getOrganismes(true).subscribe(organismes => {
      if (organismes.length === 0) {
        this.router.navigate(['/auth/no-roles']);
        return;
      }

      this.organismes = organismes.sort(this.compareOrganismes);


      this.formGroup = this.fb.group({
        organisme: [undefined, Validators.required]
      });

      if (organismes.length === 1 ) {
        this.organisme.setValue(organismes[0]);
        this.submit();
      } else if(SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR){
        this.organisme.setValue(organismes);
        this.submit();
      } 
      else {
        this.show = true;
      }
    });
  }

  private compareOrganismes(organisme1: Organisme, organisme2: Organisme): number {
    const rs1 = organisme1.raisonSociale.toLowerCase();
    const rs2 = organisme2.raisonSociale.toLowerCase();

    if (rs1 > rs2) {
      return 1;
    }
    if (rs1 < rs2) {
      return -1;
    }
    return 0;
  }

  submit() {
    if (this.formGroup.valid) {
      const organisme = this.organisme.value;
      const scope = new Scope();
      scope.idDomaine = this.idDomaine;

      if(SessionContext.get('utilisateurType') !== UtilisateurTypeCode.OPERATEUR){       
        scope.idOrganisme = organisme.id;
      }

       this.authService.setScope(scope).subscribe(jwt => {
          this.onSelectOrganisme.emit({ organisme, jwt });
        });
    }
  }

}
