<div class="page-content anpp" id="content">

  <div class="row tableau">
    <div class="col-lg-12">
      <div class="cards border-radius-onglet text-center" style="background:#fff">
        <div class="row">

          <!-- Lien filtrer -->
          <div class="col-lg-5 text-left mt-4 lienMain">
            <a class="pl-4" (click)="onFilterClick()" translate><fa-icon icon="filter"></fa-icon> label.filtrer</a>
          </div>

          <div class="col-lg-2 text-center">
            <div class="pt-2 m-2 pourcentage-cadre">
              <div href="" placement="bottom" [ngbTooltip]="'page.suivi.suivi-ecoulements.pourcent-mis-a-jour-aide' | translate">
                <span class="pourcentage"><b>{{pourcentMisAJour|number: '1.0-0'}} %</b><br />
                  <small translate class="pourcentage-label">page.suivi.suivi-inventaire.pourcent-mis-a-jour&nbsp;<fa-icon icon="info-circle"></fa-icon></small>
                </span>
              </div>
            </div>
          </div>
            
          <div class="col-lg-5 text-right p-2 pr-4">
            <button type="button" class="btn btn-lg btn-secondary btnprimary" data-toggle="modal" data-target="#estimation"  (click)="onOpenEstimations()">
              <fa-icon icon="chart-line"></fa-icon> {{ 'page.suivi.suivi-ecoulements.estimations' | translate }}
            </button>
          </div>

        </div>

        <!-- Bloc de filtrage -->
        <div *ngIf="showFilters" class="bar1 p-2 filters-bar">
          <form [formGroup]="filtersForm" novalidate>
            <div class="row">
                  
              <!-- filtre campagne -->
              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="campagne" class="custom-select bar1" (change)="onCampagneChange()">
                  <option [value]="null" hidden></option>
                  <option *ngFor="let laCampagne of campagnes" [ngValue]="laCampagne"  [selected]="laCampagne.id === campagne.id">
                    {{ laCampagne.libelle }}
                  </option>
                </select>
              </div>

              <!-- filtre mois -->
              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="mois" class="custom-select bar1" (change)="onMoisChange()">
                  <option [value]="null" hidden></option>
                  <option *ngFor="let leMois of mois" [ngValue]="leMois" [selected]="leMois === numMois">
                  {{getLibelleMois(leMois)}}</option>

                </select>
              </div>              
              <!-- filtre espece -->
              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="espece" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-ecoulements.especes</option>
                  <option *ngFor="let espece of especes" value="{{ espece.code }}">
                    {{ espece.libelle }}
                  </option>
                </select>
              </div>

              <!-- filtre type entreprise -->
              <div class="col-lg-1 text-center pb-0 m-0">
                <select formControlName="type" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-ecoulements.type</option>
                  <option *ngFor="let type of references.sousTypesUtilisateurs" value="{{ type.id }}">
                    {{ type.libelle }}
                  </option>
                </select>
              </div>

              <!-- filtre raison sociale -->
              <!-- filtre terroir -->
              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="terroir" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-ecoulements.terroir</option>
                  <option *ngFor="let terroir of references.terroirs" value="{{ terroir.departements }}">
                    {{ terroir.libelle }}
                  </option>
                </select>
              </div>

              <!-- filtre région -->

              <!-- filtre déclaration reçue -->
              <div class="col-lg-2 text-center pb-0 m-0">
                <select formControlName="ecoulementRecu" class="custom-select bar1" (change)="filter()">
                  <option value="-1" translate>page.suivi.suivi-ecoulements.declaration-recue</option>
                  <option *ngFor="let etat of etats" value="{{ etat.code }}">{{ etat.libelle | translate }}</option>
                </select>
              </div>

            </div>
          </form>
        </div>
      <div class="tableScroll">
        <table class="table table-striped">      
          <thead class="sticky-top">
            <tr>
              <th scope="col">
                <div class="custom-control custom-checkbox">
                  <input 
                    type="checkbox" 
                    class="custom-control-input" 
                    id="relance" 
                    name="relance"
                    (change)="onCheckAllDeclarations($event)"
                  />
                  <label class="custom-control-label" for="relance"></label>
                </div>
              </th>
              <th scope="col" translate>page.suivi.suivi-ecoulements.operations</th>
              <th scope="col" translate>page.suivi.suivi-ecoulements.raison-sociale</th>              
              <th translate>page.suivi.suivi-ecoulements.ville</th>
              <th scope="col" translate>page.suivi.suivi-ecoulements.espece</th>
              <th colspan="2" translate>page.suivi.suivi-ecoulements.ecoulement-declare</th>
              <th translate>page.suivi.suivi-ecoulements.date-declaration</th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>              
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>              
              <th><small translate>page.suivi.suivi-ecoulements.qte-totale</small></th>
              <th><small translate>page.suivi.suivi-ecoulements.qte-bio</small></th>
              <th></th>
            </tr>          
          </thead>

          <tbody>
            <tr *ngFor="let declaration of suivisFilter; let index = index" [ngClass]="{ 'suivi-valide': declaration.dateDeclaration }">
              <td>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="relance-{{ index }}"
                    name="relance-{{ index }}"
                    [checked]="declaration.estCochee"
                    (change)="onCheckDeclaration($event, declaration)"
                  />
                  <label class="custom-control-label" for="relance-{{ index }}"></label>
                </div>
              </td>
              <td>
                <div class="btn-group dropright">
                  <div ngbDropdown placement='top-end'>
                    <button 
                      type="button"
                      class="btn btn-secondary dropdown-toggle btnprimary btn-sm"
                      ngbDropdownToggle
                    >
                      <fa-icon icon="cog"></fa-icon> 
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                      <button type="button" class="dropdown-item" ngDropdownItem translate (click)="onGetEmails(declaration)">
                        <fa-icon icon="user"></fa-icon> page.suivi.suivi-ecoulements.action-exporter-email
                      </button>
                      <div class="dropdown-divider"></div>
                      <button type="button" class="dropdown-item" ngDropdownItem translate (click)="onGoToDeclarationEcoulement(declaration.idEntreprise)">
                        <fa-icon icon="file-alt"></fa-icon> page.suivi.suivi-ecoulements.action-ecoulements
                      </button>
                    </div>
                  </div>
                </div>	
              </td>     
              <td>{{ declaration.entreprise.raisonSociale }}</td>
              <td>{{ declaration.entreprise.commune }}</td>
              <td>
                <span *ngIf="declaration.codeEspece==='POMME'">
                  <fa-icon icon='apple-alt' style="color:black;"></fa-icon>
                </span>
                <span *ngIf="declaration.codeEspece==='POIRE'">
                  <img src="/assets/img/pear.png" width="16px" />
                </span>
              </td>              
              <td>{{ declaration.qteTotale |  saisieRecolteFormat }}</td>
              <td>{{ declaration.qteBio  | saisieRecolteFormat }}</td>
              <td><span >{{ (declaration.dateDeclaration ? (declaration.dateDeclaration | date:'shortDate') : '-' )}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>

  <!-- Bouton de relance -->    
  <div class="btn-relance">
    <div class="row">
      <div class="col-lg-8 pt-2 pb-2">
        <button
          type="button"
          class="btn btn-secondary btnprimary"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="onRelanceGroupee()"
          translate
        >
          <fa-icon icon="envelope"></fa-icon> {{ 'page.suivi.suivi-ecoulements.action-relancer' | translate }}
        </button>
      </div>
    </div>
  </div>
        
</div>
