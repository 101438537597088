import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators, FormGroup, AbstractControl} from '@angular/forms';
import {filter, remove} from 'lodash';
import {forkJoin} from 'rxjs';
import {NavigationContext, SessionContext} from 'src/app/core/services/config/app.settings';
import {OperateursService} from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import {Operateur} from 'src/app/data/intervenant/models/operateur.model';
import {OperateurInformationsDomaine} from 'src/app/data/intervenant/models/operateur-informations-domaine.model';
import {Site} from 'src/app/data/declaration/models/site.model';
import {OperateurSitesService} from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import {SitesActivitesService} from 'src/app/data/declaration/services/sites-activites/sites-activites.service';
import {SiteActivite} from 'src/app/data/declaration/models/site-activite.model';
import {UtilisateurTypeCode} from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {ActivatedRoute} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
import {IntervenantCategorie} from '../../../../../data/intervenant/models/intervenant-categorie.model';
import {
  EditInformationsMajeuresComponent
} from './modals/edit-informations-majeures/edit-informations-majeures.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  EditInformationsMineuresComponent
} from './modals/edit-informations-mineures/edit-informations-mineures.component';

@Component({
  selector: 'app-informations-operateur',
  templateUrl: './informations-operateur.component.html',
  styleUrls: ['./informations-operateur.component.scss']
})
export class InformationsOperateurComponent implements OnInit {
  static routes = [
    {
      path: '',
      component: InformationsOperateurComponent,
      data: {
        // role: 'HABILITATION_HABILITATION_LECTURE || HABILITATION_HABILITATION_ECRITURE',
        menu: {
          icon: 'building',
          libelle: 'menu.operateur-informations'
        }
      }
    }
  ];

  public sites: Site[];
  public operateur: Operateur;
  public infosDomaine: OperateurInformationsDomaine[];
  public sitesActivites: SiteActivite[];
  formCategorie: FormGroup;
  private intervenantCategorie: IntervenantCategorie;
  private informationsMajeuresExistantes: EditInformationsMajeuresComponent;

  private readonly UtilisateurTypeCode = UtilisateurTypeCode;
  private readonly SessionContext = SessionContext;
  private get idOrganisme(): number {
    return SessionContext.get('idOrganisme');
  }

  private get idOperateur(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly sitesService: OperateurSitesService,
    private readonly operateurService: OperateursService,
    private readonly modalService: NgbModal,
    private readonly sitesActivitesService: SitesActivitesService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute
  ) {
    this.formCategorie = this.fb.group({
      caveCooperative: [false],
      caveParticuliere: [false],
      negociant: [false],
      bailleur: [false],
      apporteur: [false]
    });
  }

  ngOnInit() {
    setTimeout(() => this.loadData());
    this.setupFormChanges();
  }

  loadData() {
    forkJoin(
      this.operateurService.getOperateur(this.idOperateur),
      this.operateurService.getInformationsDomaine(this.idOperateur),
      this.sitesService.getSitesOperateur(this.idOperateur),
      this.sitesActivitesService.getSitesAcitivtes(SessionContext.get('idDomaine'))
    ).pipe(
      this.loaderService.operator()
    ).subscribe(([operateur, infosDomaine, sites, sitesActivites]) => {
      this.operateur = operateur;
      this.infosDomaine = infosDomaine;
      this.sites = sites;

      if(this.idOrganisme){
        this.operateurService.getIntervenantCategorie(this.idOperateur, this.idOrganisme).subscribe((intervenantCategorie) => {
            this.intervenantCategorie = intervenantCategorie;
            if (this.intervenantCategorie) {
              this.formCategorie.patchValue(this.intervenantCategorie);
            }
        })
        
      }

      
      this.sitesActivites = sitesActivites;
    });
  }

  editSite(site: Site) {
    const formGroup = this.fb.group({
      responsableFg: this.fb.group({responsable: [site.responsable, Validators.required]}),
      adresseFg: this.fb.group({adresse: [site.adresse, Validators.required]}),
      activitesFg: this.fb.group({activites: [site.siteActivites, Validators.required]})
    });
    Object.defineProperty(site, '__form', {configurable: true, value: formGroup});
  }


  getFormGroup(site: Site, key: 'responsable' | 'adresse' | 'activites'): FormGroup {
    return (site as any).__form.get(key + 'Fg');
  }

  getFormControl(site: Site, key: 'responsable' | 'adresse' | 'activites'): AbstractControl {
    return this.getFormGroup(site, key).get(key);
  }

  cancelEditSite(site: Site, index: number) {
    if ((site as any).__new === true) {
      // remove(this.sites, site);
      this.sites.splice(index, 1);
    } else {
      delete (site as any).__form;
    }
  }

  saveSiteSuccess(site: Site, newSite: Site) {
    const index = this.sites.indexOf(site);
    this.sites.splice(index, 1, newSite);
  }

  saveSite(site: Site) {
    const form = (site as any).__form as FormGroup;
    if (form.valid) {
      const newSite = Object.assign(new Site(), site);
      newSite.adresse = this.getFormControl(site, 'adresse').value;
      newSite.responsable = this.getFormControl(site, 'responsable').value;
      newSite.siteActivites = this.getFormControl(site, 'activites').value;

      if (newSite.id) {
        this.sitesService.modifierSiteOperateur(this.idOperateur, newSite).subscribe(() => {
          this.saveSiteSuccess(site, newSite);
        });
      } else {
        this.sitesService.creerSiteOperateur(this.idOperateur, newSite).subscribe(id => {
          newSite.id = id;
          this.saveSiteSuccess(site, newSite);
        });
      }
    }
  }

  deleteSite(site: Site) {
    this.sitesService.supprimerSiteOperateur(this.idOperateur, site.id).subscribe(() => {
      remove(this.sites, site);
    });
  }

  ajouterSite(): Site {
    const site = new Site();
    Object.defineProperty(site, '__new', {configurable: true, value: true});
    this.editSite(site);
    this.sites.push(site);
    return site;
  }

  setupFormChanges() {
    this.formCategorie.valueChanges.pipe(
      debounceTime(300), // Un léger délai pour ne pas envoyer des requêtes sur chaque tap
    ).subscribe(values => {
      // Ici, nous avons besoin d'une manière pour déterminer si c'est un nouveau ou une mise à jour
      // Nous pourrions vérifier si `this.intervenantCategorie` a un ID
      if (this.intervenantCategorie && this.intervenantCategorie.id) {
        this.operateurService.updateIntervenantCategorie(this.intervenantCategorie.id, values).subscribe(
          () => console.log('Mise à jour réussie'),
          error => console.error('Erreur lors de la mise à jour', error)
        );
      } else {

        values.idIntervenant = this.idOperateur;
        values.idOrganisme = this.idOrganisme;
        this.operateurService.addIntervenantCategorie(values).subscribe(
          id => {
            console.log('Catégorie ajoutée, ID:', id);
            // Supposons que l'API retourne l'objet complet après l'ajout
            this.intervenantCategorie = {...values, id}; // Maintenant, il a un ID
            this.formCategorie.patchValue({idIntervenantCatecorie: id}); // Ajouter l'ID au formulaire pour les futures mises à jour
          },
          error => console.error('Erreur lors de l\'ajout', error)
      );
      }
    });
  }

  /**
   * Ouvre la modale pour la modification des informations Majeures
   */
  editInformationsMajeures() {
    const modaleEditInfosMajeures = this.modalService.open(EditInformationsMajeuresComponent, { backdrop: 'static', size: 'lg' });
    modaleEditInfosMajeures.componentInstance.idOperateur = this.idOperateur;
    modaleEditInfosMajeures.componentInstance.informationsMajeures = this.informationsMajeuresExistantes;
    modaleEditInfosMajeures.componentInstance.operateur = this.operateur;
    modaleEditInfosMajeures.componentInstance.infosDomaine = this.infosDomaine;

    modaleEditInfosMajeures.result.then(
      (result) => {
        if (result === 'informations_majeures_updated') {
          window.location.reload(); // Méthode pour rafraîchir les données
        }
      },
      error => {
        throw error;
      }
    );
  }

  /**
   * Ouvre la modale pour la modification des informations mineures
   */
  editInformationsMineures() {
    const modaleEditInfosMineures = this.modalService.open(EditInformationsMineuresComponent, { backdrop: 'static', size: 'lg' });
    modaleEditInfosMineures.componentInstance.idOperateur = this.idOperateur;
    modaleEditInfosMineures.componentInstance.informationsMajeures = this.informationsMajeuresExistantes;
    modaleEditInfosMineures.componentInstance.operateur = this.operateur;
    modaleEditInfosMineures.componentInstance.infosDomaine = this.infosDomaine;

    modaleEditInfosMineures.result.then(
      (result) => {
        if (result === 'informations_mineures_updated') {
          window.location.reload(); // Méthode pour rafraîchir les données
        }
      },
      error => {
        throw error;
      }
    );
  }

}
