<div class="content">
  <div class="content-panel">
    <h2 translate>page.tournees.planning.title</h2>

    <div class="row text-center">
      <!-- Bouton d'ajout -->
      <div class="col-md-1">
        <button class="btn btn-primary" [routerLink]="['../new/edit']">Ajouter</button>
      </div>
    
      <!-- Boutons de navigation -->
      <div class="col-md-3">
        <div class="btn-group">
          <div class="btn btn-primary pointer" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">{{ 'page.tournees.planning.precedent' | translate }}</div>
          <div class="btn btn-outline-secondary pointer" mwlCalendarToday [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">{{ 'page.tournees.planning.aujourdhui' | translate }}</div>
          <div class="btn btn-primary pointer" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">{{ 'page.tournees.planning.suivant' | translate }}</div>
        </div>
      </div>
    
      <!-- Afficher la date de l'agenda -->
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate: (view + 'ViewTitle'):locale:weekStartsOn }}</h3>
      </div>
    
      <!-- Boutons de sélection du type de vue -->
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-primary pointer" (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month">{{ 'page.tournees.planning.mois' | translate }}</div>
          <div class="btn btn-primary pointer" (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week">{{ 'page.tournees.planning.semaine' | translate }}</div>
          <div class="btn btn-primary pointer" (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day">{{ 'page.tournees.planning.jour' | translate }}</div>
        </div>
      </div>
    </div>
    <form class="filtre-form" [formGroup]="formFiltre" #form="ngForm">
      <label class="colon-after" translate>label.filtrer</label>
      <select formControlName="technicien" class="form-control" 
        [ngClass]="{'select-placeholder' : formFiltre.get('technicien').value === null}">
        <option [ngValue]="null" class="placeholder" translate>page.tournees.planning.select-technicien</option>
        <option *ngFor="let technicien of techniciens" [ngValue]="technicien">{{technicien.prenom}} {{technicien.nom}}</option>
      </select>
    </form>
    <br />
    
    <!-- Vue agenda -->
    <div [ngSwitch]="view" >
      <!-- Vue au mois -->
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="listeEvents"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (eventClicked)="handleEvent('Clicked', $event.event)"
        (dayClicked)="dayClicked($event.day)" (eventTimesChanged)="eventTimesChanged($event)" [locale]="locale"
        [weekStartsOn]="weekStartsOn" [weekendDays]="weekendDays" [cellTemplate]="monthCellTemplate">
      </mwl-calendar-month-view>
    
      <!-- Vue à la semaine -->
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="listeEvents"
        [refresh]="refresh" [dayStartHour]="8" [dayEndHour]="20" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)" [locale]="locale" [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays">
      </mwl-calendar-week-view>
    
      <!-- Vue à la journée -->
      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="listeEvents"
        [refresh]="refresh" [dayStartHour]="8" [dayEndHour]="20" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)" [locale]="locale">
      </mwl-calendar-day-view>
    </div>
    
    <ng-template #monthCellTemplate let-day="day" let-locale="locale">
      <div class="cal-cell-top">
        <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
          >{{ day.badgeTotal }}</span
        > -->
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <div class="event-month-container">
        <div *ngFor="let event of day.events | slice:0:maxEventsMonthView" placement="top" [ngbTooltip]="tipContent"
          [ngStyle]="{'background-color':event.color.secondary,'border-color':event.color.primary }" class="event-month">
          <strong>{{event.meta.tournee.numero}}</strong>
          <!-- <span class="overflow-ellipsis">{{event.meta.tournee.salle.nom}}</span> -->
          <span>{{event.meta.tournee.debut | date:'H:mm'}} - {{event.meta.tournee.fin | date:'H:mm'}}</span>
          <ng-template #tipContent>
            <div class="month-event-tooltip" [innerHTML]="event.title"></div>
          </ng-template>
        </div>
        <div *ngIf="day.events.length > maxEventsMonthView">
          + {{day.events.length-maxEventsMonthView}} {{'label.autres' | translate | lowercase}}
        </div>
      </div>
      <!-- <small style="margin: 5px"
        >There are {{ day.events.length }} events on this day</small
      > -->
    </ng-template>

  </div>
</div>