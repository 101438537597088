import {Component, OnInit} from '@angular/core';
import {Route, ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SyntheseVolumesService} from 'src/app/data/declaration/services/synthese-volumes/synthese-volumes.service';
import {SyntheseVolumesAOC} from 'src/app/data/declaration/models/synthese-volumes-aoc.model';
import {ChangementsDenominationComponent} from './modals/changements-denomination/changements-denomination.component';
import {SaisirConditionnementComponent} from './modals/saisir-conditionnement/saisir-conditionnement.component';
import {SessionContext, NavigationContext} from 'src/app/core/services/config/app.settings';
import {SyntheseVolumesIGP} from 'src/app/data/declaration/models/synthese-volumes-igp.model';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {RechercheProduitComponent} from './modals/recherche-produit/recherche-produit.component';
import {TypeCahierCode} from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import {Produit} from 'src/app/data/habilitation/models/produit.model';
import {Fail} from 'src/app/shared/errors/fail.error';
import {SaisirRevendicationComponent} from './modals/saisir-revendication/saisir-revendication.component';
import {ChoixStockComponent} from './modals/choix-stock/choix-stock.component';
import {StockCode} from 'src/app/data/declaration/models/enums/stock.enum';
import {ProduitsService} from 'src/app/data/declaration/services/produits/produits.service';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {SaisirTransactionComponent} from './modals/saisir-transaction/saisir-transaction.component';
import {
  SaisirConditionnementIgpComponent
} from './modals/saisir-conditionnement-igp/saisir-conditionnement-igp.component';
import {
  SyntheseSaisieRevendicationComponent
} from './modals/saisir-revendication/synthese-saisie-revendication/synthese-saisie-revendication.component';
import {SaisieRevendicationResult} from 'src/app/data/declaration/interfaces/saisie-revendication-result.interface';
import {LotSaisiesRevendications} from 'src/app/data/declaration/models/lot-saisies-revendications.model';
import {RefCampagnesService} from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import {DonneesDrComponent} from './modals/donnees-dr/donnees-dr.component';
import {UtilisateurTypeCode} from '../../../../../data/intervenant/models/enums/type-utilisateur.enum';
import { SaisirTirageComponent } from './modals/saisir-tirage/saisir-tirage.component';
import { CahiersService } from 'src/app/data/commission/services/cahiers/cahiers.service';

@Component({
  selector: 'app-synthese-volumes',
  templateUrl: './synthese-volumes.component.html',
  styleUrls: ['./synthese-volumes.component.scss']
})
export class SyntheseVolumesComponent implements OnInit {
  static routes: Route[] = [
    {path: '', component: SyntheseVolumesComponent, data: {menu: {libelle: 'Synthèse', icon: 'balance-scale'}}},
    {path: ':campagne', component: SyntheseVolumesComponent}
  ];

  public synthesesVolumesAOC: SyntheseVolumesAOC[];
  public synthesesVolumesIGP: SyntheseVolumesIGP[];
  public formGroup: FormGroup;
  public useAOC: Boolean;
  public useIGP: Boolean;

  protected readonly SessionContext = SessionContext;
  protected readonly UtilisateurTypeCode = UtilisateurTypeCode;

  get campagne() {
    return this.formGroup.get('campagne');
  }

  constructor(
    private readonly syntheseVolumesService: SyntheseVolumesService,
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly refCampagnesService: RefCampagnesService,
    private readonly produitsService: ProduitsService,
    private readonly cahiersService: CahiersService,
    private readonly loader: AdvBootstrapLoaderService
  ) {
  }

  get idIntervenant(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  ngOnInit() {

    this.formGroup = this.fb.group({
      campagne: [undefined, [Validators.required, Validators.pattern(/(19|20)\d\d/)]]
    });

    this.route.params.subscribe(vals => {
      const campagne = vals.campagne;
      if (campagne) {
        this.campagne.setValue(campagne);
        this.loadData(campagne);
      } else {
        this.refCampagnesService.getCampagneEnCours().subscribe(campagne => {
          this.campagne.setValue(campagne.annee.toString());
          this.loadData(campagne.annee.toString());
        })
      }
    });

    this.campagne.valueChanges.subscribe(campagne => {
      if (this.campagne.valid) {
        this.synthesesVolumesAOC = null;
        this.synthesesVolumesIGP = null;
        this.router.navigate([campagne], {relativeTo: this.route.parent});
      }
    });

  }

  loadData(campagne: string) {
    // Récupérer les synthèses AOC et IGP

    

    const sources = [this.cahiersService.getUseTypeCahier(this.idIntervenant,'AOC'),this.cahiersService.getUseTypeCahier(this.idIntervenant,'IGP'),this.syntheseVolumesService.getSyntheseVolumesAOC(this.idIntervenant, campagne),
      this.syntheseVolumesService.getSyntheseVolumesIGP(this.idIntervenant, campagne)]
    forkJoin(
      sources
    ).pipe(
      this.loader.operator()
    ).subscribe(([useAOC,useIGP,synthesesAOC, synthesesIGP]) => {
      this.useAOC = useAOC;
      this.useIGP = useIGP;
      this.synthesesVolumesAOC = synthesesAOC;
      this.synthesesVolumesIGP = synthesesIGP;
    });
  }

  // ###############################
  // ###########  AOC  #############
  // ###############################
  changementDenomination(synthese: SyntheseVolumesAOC) {
    const modal = this.modalService.open(ChangementsDenominationComponent, {
      backdrop: 'static',
      windowClass: 'app-modal-1000px'
    });
    modal.componentInstance.produitsOrigine = [synthese.produit];
    modal.componentInstance.campagne = this.campagne.value;
    modal.componentInstance.typeCahier = TypeCahierCode.AOC;
    modal.componentInstance.stockOrigine = StockCode.REVENDIQUE;

    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }

  saisirConditionnement(synthese: SyntheseVolumesAOC) {
    const modal = this.modalService.open(SaisirConditionnementComponent, {
      backdrop: 'static',
      windowClass: 'app-modal-1000px'
    });
    modal.componentInstance.syntheseProduit = synthese.produit;
    modal.componentInstance.campagne = this.campagne.value;

    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }

  // ###############################
  // ###########  IGP  #############
  // ###############################

  changementDenominationIgp(synthese: SyntheseVolumesIGP) {
    const modal = this.modalService.open(ChangementsDenominationComponent, {
      backdrop: 'static',
      windowClass: 'app-modal-1000px'
    });
    modal.componentInstance.produitsOrigine = [synthese.produit];
    modal.componentInstance.campagne = this.campagne.value;
    modal.componentInstance.typeCahier = TypeCahierCode.IGP;
    modal.componentInstance.stockOrigine = StockCode.REVENDIQUE;

    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }

  SaisirRevendicationIgp(
    lotRevendications: LotSaisiesRevendications = undefined,
    indexAModifier: number = undefined
  ) {
    const modalSaisie = this.modalService.open(SaisirRevendicationComponent, {backdrop: 'static', size: 'lg'});
    modalSaisie.componentInstance.campagne = this.campagne.value;
    if (lotRevendications) {
      modalSaisie.componentInstance.lotRevendications = lotRevendications;
    }
    if (indexAModifier > -1) {
      modalSaisie.componentInstance.indexModification = indexAModifier;
    }

    // Ouvrir la modal de saisie par lot
    modalSaisie.result.then((lotSaisiesRevendications) => {
      // Ouvrir la modal de synthèse
      const modalSyntheseSaisie = this.modalService.open(SyntheseSaisieRevendicationComponent, {
        backdrop: 'static',
        size: 'lg'
      });
      modalSyntheseSaisie.componentInstance.lotRevendications = lotSaisiesRevendications;
      modalSyntheseSaisie.result.then((result: SaisieRevendicationResult) => {
        if (result && result.saisieTerminee) {
          // Recharger les données
          this.loadData(this.campagne.value);
        } else if (result) {
          // Ré-ouvrir la modal de saisie par lot
          this.SaisirRevendicationIgp(result.lotRevendications, result.indexAModifier);
        }
      }, () => {
      });
    }, () => {
    });
  }

  saisirConditionnementIgp(synthese: SyntheseVolumesIGP) {
    const modal = this.modalService.open(SaisirConditionnementIgpComponent, {
      backdrop: 'static',
      windowClass: 'app-modal-1000px'
    });
    modal.componentInstance.syntheseProduit = synthese.produit;
    modal.componentInstance.campagne = this.campagne.value;

    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }


  modifierDr(synthese: SyntheseVolumesIGP) {
    const modal = this.modalService.open(DonneesDrComponent, {backdrop: 'static', windowClass: 'app-modal-60'});
    modal.componentInstance.syntheseProduit = synthese.produit;
    modal.componentInstance.annee = this.campagne.value;
    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }

  // ###############################
  // #########  AOC + IGP ##########
  // ###############################

  changementDenominationHorsBacchus(type: TypeCahierCode) {
    this.produitsService.getProduits(this.idIntervenant, false).pipe(
      this.loader.operator()
    ).subscribe(produits => {

      const modal = this.modalService.open(ChangementsDenominationComponent, {
        backdrop: 'static',
        windowClass: 'app-modal-1000px'
      });
      modal.componentInstance.produitsOrigine = produits;
      modal.componentInstance.campagne = this.campagne.value;
      modal.componentInstance.typeCahier = type;
      modal.componentInstance.stockOrigine = type === TypeCahierCode.IGP ? StockCode.ISSU_DR : StockCode.REVENDIQUE;
      modal.componentInstance.origineHorsBacchus = true;

      modal.result.then(() => {
        this.loadData(this.campagne.value);
      }, () => {
      });
    });
  }

  ajoutProduit(type: TypeCahierCode) {
    const modal = this.modalService.open(RechercheProduitComponent, {backdrop: 'static'});
    modal.componentInstance.typeCahierCode = type;
    modal.componentInstance.produitBacchus = true;
    modal.componentInstance.idOperateur = this.idIntervenant;

    switch (type) {
      case TypeCahierCode.AOC:
        modal.componentInstance.alreadyPresentIds = this.synthesesVolumesAOC.map(item => item.produit.id);
        break;
      case TypeCahierCode.IGP:
        modal.componentInstance.alreadyPresentIds = this.synthesesVolumesIGP.map(item => item.produit.id);
        break;
      default:
        Fail.never(type);
    }

    modal.result.then((produit: Produit) => {
      switch (type) {
        case TypeCahierCode.AOC:
          this.synthesesVolumesAOC.push(Object.assign(new SyntheseVolumesAOC(), {produit, codeProduit: produit.code}));
          break;
        case TypeCahierCode.IGP:
          this.synthesesVolumesIGP.push(Object.assign(new SyntheseVolumesIGP(), {produit, codeProduit: produit.code}));
          break;
        default:
          Fail.never(type);
      }
    }, () => {
    });
  }

  transaction(typeCahier: TypeCahierCode, produit: Produit) {
    const modal = this.modalService.open(SaisirTransactionComponent, {
      backdrop: 'static',
      windowClass: 'app-modal-1000px'
    });
    modal.componentInstance.typeCahier = typeCahier;
    modal.componentInstance.produit = produit;
    modal.componentInstance.campagne = this.campagne.value;

    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }

  tirage(typeCahier: TypeCahierCode, synthese: SyntheseVolumesAOC) {
    const modal = this.modalService.open(SaisirTirageComponent, {
      backdrop: 'static',
      windowClass: 'app-modal-1000px'
    });
    modal.componentInstance.typeCahier = typeCahier;
    modal.componentInstance.syntheseProduit = synthese.produit;
    modal.componentInstance.campagne = this.campagne.value;

    modal.result.then(() => {
      this.loadData(this.campagne.value);
    }, () => {
    });
  }
}
