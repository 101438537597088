import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle as farCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faBan, faBuilding, faCalendarAlt, faCertificate, faCheckCircle, faEdit, faPlus, faSort, faSortDown, faSortUp, faTachometerAlt,
  faUsers, faUsersCog, faUserTag, faChartBar, faFileSignature, faBell, faCheck, faChevronUp, faChevronDown, faTrash, faUndo, faSave,
  faArrowCircleRight, faExclamation, faExchangeAlt, faClipboardList, faBars, faPen, faUserCheck, faUserTimes, faUserSlash, faUserMinus,
  faUser, faEnvelope, faEnvelopeOpen, faWineGlassAlt, faUserPlus, faWineBottle, faBalanceScale, faMinus, faSignInAlt, faEuroSign, faArrowLeft,
  faCube, faCubes, faKey, faSearch, faEllipsisH, faMinusCircle, faInfoCircle, faTimes, faDownload, faUserCircle, faAt, faLock, faClipboardCheck,
  faCopyright, faCopy, faAddressBook, faCog, faChevronRight, faMapMarkedAlt, faCoins, faFolderOpen, faFileAlt, faCalendar, faFileContract, faAppleAlt, 
  faChartLine, faIdCard, faCommentDots, faFilter, faPlusSquare, faArrowRight, faPencilAlt, faTrashAlt, faCogs, faLeaf, faUpload, faList, faIdCardAlt, 
  faHammer, faChevronLeft, faExclamationTriangle, faFolder, faArchive, faMedal, faDivide, faEye, faSortNumericDown
} from '@fortawesome/free-solid-svg-icons';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { NgSelectModule } from '@ng-select/ng-select';

import { LanguageDropdownComponent } from 'src/app/shared/components/language-dropdown/language-dropdown.component';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { HasHabilitationDirective } from './directives/has-habilitation/has-habilitation.directive';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { PaginationBarComponent } from './components/pagination-bar/pagination-bar.component';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { PhoneFormatPipe } from './pipes/phoneFormat/phone-format-pipe';
import { HasDomaineDirective } from './directives/has-domaine/has-domaine.directive';
import { SaisieRecolteFormatPipe } from './pipes/saisieRecolteFormat/saisie-recolte-format.pipe';
import { SearchAdherentActifBarComponent } from './components/search-entreprise/search-adherent-actif-bar/search-adherent-actif-bar.component';
import { SearchProducteurOrganiseBarComponent } from './components/search-entreprise/search-producteur-organise-bar/search-producteur-organise-bar.component';
import { SortableDirective } from './directives/sortable/sortable.directive';
import { SearchEntrepriseCategorieBarComponent } from './components/search-entreprise/search-entreprise-categorie-bar/search-entreprise-categorie-bar.component';
import { SearchIntervenantOrganismeBarComponent } from './components/search-intervenant/search-intervenant-organisme-bar/search-intervenant-organisme-bar.component';

@NgModule({
  declarations: [
    LanguageDropdownComponent,
    HasRoleDirective,
    FilterPipe,
    MultiselectComponent,
    HasHabilitationDirective,
    SearchBarComponent,
    PaginationBarComponent,
    TruncatePipe,
    PhoneFormatPipe,
    HasDomaineDirective,
    SaisieRecolteFormatPipe,
    SearchAdherentActifBarComponent,
    SearchProducteurOrganiseBarComponent,
    SortableDirective,
    SearchEntrepriseCategorieBarComponent, 
    SearchIntervenantOrganismeBarComponent 
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    LanguageDropdownComponent,
    NgbModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    HasRoleDirective,
    OrderModule,
    NgSelectModule,
    FilterPipe,
    TruncatePipe,
    MultiselectComponent,
    DragDropModule,
    HasHabilitationDirective,
    SearchBarComponent,
    PaginationBarComponent,
    PhoneFormatPipe,
    HasDomaineDirective,
    SaisieRecolteFormatPipe,
    SearchAdherentActifBarComponent,
    SearchProducteurOrganiseBarComponent,
    SearchEntrepriseCategorieBarComponent,
    SearchIntervenantOrganismeBarComponent,
    SortableDirective
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    OrderModule,
    NgSelectModule,
    DragDropModule
  ]
})
export class SharedModule {
  constructor() {
    library.add(faTachometerAlt);
    library.add(faCalendarAlt);
    library.add(faCertificate);
    library.add(faPlus);
    library.add(faPlusSquare);
    library.add(faMinus);
    library.add(faMinusCircle);
    library.add(faBan);
    library.add(faCheckCircle);
    library.add(farCheckCircle);
    library.add(faUsersCog);
    library.add(faBuilding);
    library.add(faSortUp);
    library.add(faSortDown);
    library.add(faSort);
    library.add(faEdit);
    library.add(faCheckCircle);
    library.add(faUsers);
    library.add(faUserTag);
    library.add(faChartBar);
    library.add(faFileSignature);
    library.add(faBell);
    library.add(faCheck);
    library.add(faChevronDown);
    library.add(faChevronUp);
    library.add(faTrash);
    library.add(faUndo);
    library.add(faSave);
    library.add(faArrowCircleRight);
    library.add(faExclamation);
    library.add(faExchangeAlt);
    library.add(faClipboardList);
    library.add(faBars);
    library.add(faPen);
    library.add(faEnvelopeOpen);
    library.add(faEnvelope);
    library.add(faUserCheck);
    library.add(faUserTimes);
    library.add(faUserSlash);
    library.add(faUserMinus);
    library.add(faUserPlus);
    library.add(faUser);
    library.add(faWineGlassAlt);
    library.add(faWineBottle);
    library.add(faBalanceScale);
    library.add(faSignInAlt);
    library.add(faEuroSign);
    library.add(faArrowLeft);
    library.add(faCubes);
    library.add(faKey);
    library.add(faSearch);
    library.add(faEllipsisH);
    library.add(faTrash);
    library.add(faEdit);
    library.add(faInfoCircle);
    library.add(faTimesCircle);
    library.add(faTimes);
    library.add(faDownload);
    library.add(faUserCircle);
    library.add(faAt);
    library.add(faLock);
    library.add(faSignInAlt);
    library.add(faClipboardCheck);
    library.add(faEuroSign);
    library.add(faCopy);
    library.add(faWineBottle);
    library.add(faAddressBook);
    library.add(faCog);
    library.add(faChevronRight);
    library.add(faMapMarkedAlt);
    library.add(faCoins);
    library.add(faFileAlt);
    library.add(faFolderOpen);
    library.add(faCalendar);
    library.add(faEuroSign);
    library.add(faFileContract);
    library.add(faHammer);
    library.add(faList);
    library.add(faIdCardAlt);
    library.add(faIdCard);
    library.add(faUpload);
    library.add(faAppleAlt);
    library.add(faCommentDots);
    library.add(faFilter);
    library.add(faCogs);
    library.add(faPlusSquare)
    library.add(faArrowRight);
    library.add(faPencilAlt);
    library.add(faTrashAlt);
    library.add(faCogs);
    library.add(faLeaf);
    library.add(faChartLine);
    library.add(faChevronLeft);
    library.add(faExclamationTriangle);
    library.add(faMedal);
    library.add(faFolder);
    library.add(faArchive);
    library.add(faDivide);
    library.add(faEye);
    library.add(faSortNumericDown);
  }
}
