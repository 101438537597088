<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.commissions.edit.modals.echantillons.affecter.title
    </h4>
  </div>

  <div class="modal-body">
      <app-search-bar [(value)]="searchString" *ngIf="echantillonsForms.length > 0"></app-search-bar>

      <form [formGroup]="affectationsForm" #form="ngForm" (submit)="submit()" novalidate>
        <table formArrayName="liste" class="table" *ngIf="echantillonsForms.length > 0; else aucunEchantillon">
          <thead>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.echantillon.echantillon.produit.libelleCahier', libelle:'page.commissions.edit.modals.echantillons.table.header.cahier'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.echantillon.echantillon.produit.libelle', libelle:'page.commissions.edit.modals.echantillons.table.header.produit'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.echantillon.echantillon.millesime', libelle:'page.commissions.edit.modals.echantillons.table.header.millesime'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.echantillon.echantillon.operateur.siret', libelle:'page.commissions.edit.modals.echantillons.table.header.operateur'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.echantillon.echantillon.datePrelevement', libelle:'page.commissions.edit.modals.echantillons.table.header.datePrelevement'}"></ng-container>
            <ng-container *ngTemplateOutlet="tableHeader;context:{sortProperty:'item.echantillon.echantillon.degre', libelle:'page.commissions.edit.modals.echantillons.table.header.degre'}"></ng-container>
            <th translate>page.commissions.edit.modals.echantillons.table.header.numeroAnonymat <span   (click)="numeroter()" title="{{'page.commissions.edit.modals.echantillons.table.header.numeroAnonymatTitle' | translate}}" ><fa-icon class="clickable-icon" icon="sort-numeric-down"  ></fa-icon></span></th>
            <th translate>page.commissions.edit.modals.echantillons.table.header.jury</th> 
          </thead>
          <tbody>
            <tr [formGroup]="item.form" *ngFor="let item of echantillonsForms | filter: 'echantillon':searchString:4 | orderBy:order.property:order.reverse:true">
              <ng-container *ngIf="item.echantillon.echantillon && item.echantillon.echantillon.produit">
              <td>{{item.echantillon.echantillon.produit?.libelleCahier}}</td>
              <td>{{item.echantillon.echantillon.produit?.libelle}}</td>
              <td>{{item.echantillon.echantillon.millesime}}</td>
              <td>{{item.echantillon.echantillon.operateur?.siret}} - {{item.echantillon.echantillon.operateur?.raisonSociale}}</td>
              <td>{{item.echantillon.echantillon.datePrelevement | date:'shortDate' }}</td>
              <td>{{item.echantillon.echantillon.degre}}</td>
            </ng-container >
            <ng-container  *ngIf="item.echantillon.leurre && item.echantillon.leurre.produit">
              <td>{{item.echantillon.leurre.produit?.libelleCahier}}</td>
              <td>{{item.echantillon.leurre.produit?.libelle}}</td>
              <td>{{item.echantillon.leurre.millesime}}</td>
              <td></td>
              <td></td>
              <td></td>
            </ng-container >
              <td><input type="text" class="form-control" formControlName="anonymat" /></td>
              <td>
                <input type="number" step="1" min="0" size="2" class="form-control" [ngClass]="{'is-invalid': form.submitted && item.form.get('jury').invalid}" formControlName="jury" >
              </td>
            </tr>
          </tbody>
        </table>
      <div class="modal-footer">
        <button type="submit" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>


<ng-template #aucunEchantillon>
    <div translate>page.commissions.edit.modals.echantillons.affecter.aucun_echantillon</div>
</ng-template>

<ng-template #tableHeader let-property="sortProperty" let-libelle="libelle">
  <th *ngIf="property" (click)="setOrder(property)" [class.sortable]="property">
    {{libelle | translate}}
    <fa-icon *ngIf="property" [icon]="getIcon(property)"></fa-icon>
  </th>
  <th *ngIf="!property">{{libelle | translate}}</th>
</ng-template>
