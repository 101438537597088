import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { RefCampagnesFacade } from 'src/app/data/declaration/facades/ref-campagnes/ref-campagnes.facade';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { Espece } from 'src/app/data/declaration/models/espece.model';
import { EspeceService } from 'src/app/data/declaration/services/parcellaire/espece.service';
import { References } from 'src/app/data/intervenant/models/references.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { EntrepriseReferent } from 'src/app/data/intervenant/models/entreprise-referent.model';
import { RecoltesFacade } from 'src/app/data/declaration/facades/recoltes/recoltes.facade';
import { SaisieRecolte } from 'src/app/data/declaration/models/saisie-recolte.model';
import { pull } from 'lodash';
import { ContactReferent } from 'src/app/data/intervenant/models/contact-referent.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NavigatorExtended } from 'src/app/shared/interfaces/navigator-extended.interface';
import { EmailsHelper } from 'src/app/shared/helpers/emails.helper';
import { Email } from 'src/app/shared/interfaces/email.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EstimationsRecoltesComponent } from './modals/estimations-recoltes/estimations-recoltes.component';
import { RecoltesService } from 'src/app/data/declaration/services/recoltes/recoltes.service';
import { SuivisService } from 'src/app/data/suivis/services/suivis.service';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

@Component({
  selector: 'app-suivi-declarations-recolte',
  templateUrl: './suivi-declarations-recolte.component.html',
  styleUrls: ['./suivi-declarations-recolte.component.scss']
})
export class SuiviDeclarationsRecolteComponent implements OnInit, OnDestroy {

  // Routes
  public static routes: Routes = [{
    path: '', component: SuiviDeclarationsRecolteComponent, data: {
      role: 'ORGANISME',
      domaines: ['ANPP'],
      menu: {
        libelle: 'menu.recoltes',
        icon: 'cogs'
      }
    }
  }];

  // Propriétés privées
  private declarationsRecoltes: Array<SaisieRecolte> = [];
  private entreprises: Array<EntrepriseReferent> = [];
  private idEntrepriseList: Array<number> = [];
  private unsubscribe$ = new Subject<void>();
  // getters privés
  private get _campagne(): AbstractControl { return this.filtersForm.get('campagne'); }
  private get _espece(): AbstractControl { return this.filtersForm.get('espece'); }
  private get _type(): AbstractControl { return this.filtersForm.get('type'); }
  private get _terroir(): AbstractControl { return this.filtersForm.get('terroir'); }
  private get _nbJours(): AbstractControl { return this.filtersForm.get('nbJours'); }
  private get _previsions(): AbstractControl { return this.filtersForm.get('previsions'); }
  private get _recoltes(): AbstractControl { return this.filtersForm.get('recoltes'); }
  // Propriétés publiques
  public campagnes$: Observable<Array<Campagne>> = this.refCampagnesFacade.refCampagnesWithDateDebutOrderByYearsDESC$;
  public entreprisesSelectionnees: Array<number> = [];
  public especes$: Observable<Array<Espece>> = this.especeService.getEspeces();
  public etats: Array<{ code: number, libelle: string }> = [
    { code: 1, libelle: 'page.suivi.suivi-recoltes.manquantes' },
    { code: 2, libelle: 'page.suivi.suivi-recoltes.recues' }
    //{ code: 3, libelle: 'page.suivi.suivi-recoltes.manquantes-et-recues' }
  ];
  public filtersForm: FormGroup;
  public filteredDeclarations: Array<SaisieRecolte>;
  public nbJours: Array<{ code: number, libelle: string }> = [
    { code: 1, libelle: 'page.suivi.suivi-recoltes.superieur-20' },
    { code: 2, libelle: 'page.suivi.suivi-recoltes.inferieur-20' }
  ];
  public previsionsStat = 0;
  public recoltesStat = 0;
  public references: References;
  public showFilters: boolean = false;

  constructor(
    private declarationsService: DeclarationsService,
    private entrepriseService: EntreprisesService,
    private especeService: EspeceService,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private recoltesFacade: RecoltesFacade,
    private recoltesService: RecoltesService,
    private refCampagnesFacade: RefCampagnesFacade, 
    private referencesService: ReferencesService,
    private router: Router,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private suivisService: SuivisService,
    private campagneService: RefCampagnesService,
    private loaderService: AdvBootstrapLoaderService
  ) { }

  public ngOnInit(): void {
    this.suivisService.setActiveTabId('recoltes');
    // Charger les données initiales
    this.entrepriseService.getEntreprisesReferents()
      .pipe(
        takeUntil(this.unsubscribe$),
        map((entreprises: Array<EntrepriseReferent>) => this.entreprises = entreprises.filter(e=>['PI_DEFAUT', 'GP_DEFAUT'].indexOf(e.sousType.code)>=0)),
        this.loaderService.operator()
      )
      .subscribe(() => {
        this.previsionsStat = this.recoltesFacade.getPrevisionsStat(this.filteredDeclarations);
        this.recoltesStat = this.recoltesFacade.getRecoltesStat(this.filteredDeclarations);
        this.idEntrepriseList = this.entreprises.map(e => e.id);
      });
    this.referencesService.getReferences()
      .pipe(map((references: References) => this.references = references), this.loaderService.operator())
      .subscribe();
    this.refCampagnesFacade.loadRefCampagnes();
    //this.recoltesFacade.loadDeclarationsRecoltes();

    // Initialiser le formulaire de recherche avancée
    this.filtersForm = this.formBuilder.group({
      campagne: [-1],
      espece: [-1],
      type: [-1],
      terroir: [-1],
      nbJours: [-1],
      previsions: [-1],
      recoltes: [-1]
    });
    this._campagne.valueChanges
     .pipe(
       debounceTime(200),
       distinctUntilChanged(),
       switchMap((idCampagne: number) => { 
        if(idCampagne) {
          return this.recoltesService.getAllDeclarationsRecoltes(idCampagne).pipe(this.loaderService.operator())
        }
       })
      ).subscribe((recoltes: Array<SaisieRecolte>) => {
        this.declarationsRecoltes = this.addEntreprisesWithoutDeclaration(this.recoltesFacade.splitDeclarationsRecoltesByEspeces(recoltes));
        this.declarationsRecoltes = this.declarationsRecoltes.filter(r => this.idEntrepriseList.includes(r.idEntreprise));
        this.campagneService.getIdEntrepriseDroitRecolteFalse(this._campagne.value).subscribe(entrepriseSandroit => {
          if(entrepriseSandroit) {
            this.declarationsRecoltes = this.declarationsRecoltes.filter(r => !entrepriseSandroit.includes(r.idEntreprise));
          }
         
        });
        this.previsionsStat = this.recoltesFacade.getPrevisionsStat(this.filteredDeclarations);
        this.recoltesStat = this.recoltesFacade.getRecoltesStat(this.filteredDeclarations);
        this.filter();
      });

      this.campagneService.getCampagneEnCours().subscribe((campagne) => {
        this.campagnes$.subscribe(campagnes => {
          const camp =  campagnes.find(c => c.id == campagne.id);
          this._campagne.setValue(camp.id);
        });
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getLibelleEntreprise(idEntreprise: number): string {
    const entreprise = this.entreprises.find((entreprise: EntrepriseReferent) => entreprise.id == idEntreprise);
    return entreprise ? entreprise.raisonSociale : '';
  }

  public onCheckAllDeclarations(event: any): void {
    this.entreprisesSelectionnees = [];
    this.filteredDeclarations.forEach((declaration: SaisieRecolte) => {
      declaration.estCochee = event.target.checked;
      if (event.target.checked && !this.entreprisesSelectionnees.includes(declaration.idEntreprise)) {
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
      }
    });
  }
  
  public onCheckDeclaration(event: Event, declaration: SaisieRecolte): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    // Met à jour l'état 'estCochee' de la déclaration selon l'état de la case cochée
    declaration.estCochee = isChecked;

    // Vérifie si la case est cochée et l'entreprise n'est pas encore dans la liste
    if (isChecked && !this.entreprisesSelectionnees.includes(declaration.idEntreprise)) {
        // Ajoute l'entreprise sélectionnée à la liste
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
    } 
    // Sinon, si la case est décochée et que l'entreprise est dans la liste
    else if (!isChecked) {
        // Retire l'entreprise de la liste
        pull(this.entreprisesSelectionnees, declaration.idEntreprise);
    }
}

  public onFilterClick(): void {
    this.showFilters = !this.showFilters;
  }

  public onGetEmails(idEntreprise: number): void {
    const referentsEmails = this.getReferentsEmails(idEntreprise);
    if (referentsEmails != null) {
      (navigator as NavigatorExtended).clipboard.writeText(referentsEmails.join(',')).then(() => 
        this.toastrService.success(this.translateService.instant('page.suivi.suivi-recoltes.emails-copie-succes'))
      );
    } else {
      this.toastrService.error(this.translateService.instant('page.suivi.suivi-recoltes.emails-copie-erreur'));
    }
  }

  public onGoToPrevisionRecolte(idEntreprise: number): void {
    this.declarationsService.entrepriseSelectionnee = <Entreprise>{ 
      id: idEntreprise,
      raisonSociale: this.getLibelleEntreprise(idEntreprise)
    };
    this.router.navigate([`/main/declarations-pp/recoltes/${idEntreprise}`]);
  }

  public onOpenEstimations(): void {
    this.modal.open(EstimationsRecoltesComponent, { backdrop: 'static', windowClass: 'app-modal-1001px' });
  }

  public onRelance(idEntreprise: number): void {
    const referentsEmails = this.getReferentsEmails(idEntreprise);
    if (referentsEmails != null && referentsEmails.length > 0) {
      EmailsHelper.envoyerEMail(<Email>{ to: referentsEmails });
    } else {
      this.toastrService.error(this.translateService.instant('page.suivi.suivi-recoltes.emails-copie-erreur'));
    }
  }

  public onRelanceGroupee(): void {
    let referentsEmails: Array<string> = [];
    this.entreprisesSelectionnees.forEach((idEntreprise: number) => 
      referentsEmails = referentsEmails.concat(this.getReferentsEmails(idEntreprise))
    );
    if (referentsEmails != null && referentsEmails.length > 0) {
      (navigator as NavigatorExtended).clipboard.writeText(referentsEmails.join(';')).then(() => {
        this.toastrService.success(
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-succes'),
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-titre'),
          { timeOut: 10 * 1000 }
        );
      }
      ).catch(e => console.error(e));
    } else {
      this.toastrService.error(this.translateService.instant('page.suivi.suivi-recoltes.emails-copie-erreur'));
    }
  }

  public filter(): void {
    const espece = this._espece.value;
    const type = this._type.value;
    const terroir = this._terroir.value;
    const nbJours = this._nbJours.value;
    const previsions = this._previsions.value;
    const recoltes = this._recoltes.value;
    
    this.filteredDeclarations = this.declarationsRecoltes.filter((declaration: SaisieRecolte) => {
      const entreprise = this.entreprises.find((entreprise: EntrepriseReferent) => entreprise.id == declaration.idEntreprise);
      if (espece != -1 && declaration.recolteSuivi.idEspece != espece) {
        return false;
      }
      if (type != -1 && (entreprise == null || entreprise.sousType.id != type)) {
        return false;
      }
      if (terroir != -1 && (entreprise == null || entreprise.departement.indexOf(terroir) == -1)) {
        return false;
      }
      if (
        nbJours != -1 && (
          !declaration.delai ||
          (nbJours == 1 && declaration.delai < 20) || 
          (nbJours == 2 && declaration.delai > 20)
      )) {
        return false;
      }
      if ((previsions == 1 && declaration.recolteSuivi.datePrevisionActuelle) || (previsions == 2 && !declaration.recolteSuivi.datePrevisionActuelle)) {
        return false;
      }
      if ((recoltes == 1 && declaration.recolteSuivi.dateSaisieRecolte) || (recoltes == 2 && !declaration.recolteSuivi.dateSaisieRecolte)) {
        return false;
      }

      return true;
    });
    this.sortList();
    this.previsionsStat = this.recoltesFacade.getPrevisionsStat(this.filteredDeclarations);
    this.recoltesStat = this.recoltesFacade.getRecoltesStat(this.filteredDeclarations);
  }
  
  // tri par espece (Pomme, poire) puis raison sociale
  private sortList() {
    this.filteredDeclarations.sort(
      (n1,n2)=>{
        n1.recolteSuivi.codeEspece
        if (n1.recolteSuivi.codeEspece === undefined && n2.recolteSuivi.codeEspece === undefined ) return this.getLibelleEntreprise(n1.idEntreprise).localeCompare(this.getLibelleEntreprise(n2.idEntreprise));
        if (n1.recolteSuivi.codeEspece && n2.recolteSuivi.codeEspece === undefined ) return -1;
        if (n1.recolteSuivi.codeEspece === undefined && n2.recolteSuivi.codeEspece ) return 1;
        if (n1.recolteSuivi.codeEspece === n2.recolteSuivi.codeEspece ) return this.getLibelleEntreprise(n1.idEntreprise).localeCompare(this.getLibelleEntreprise(n2.idEntreprise));
        if (n1.recolteSuivi.codeEspece === "POMME" && n2.recolteSuivi.codeEspece === 'POIRE' ) return -1;
        if (n1.recolteSuivi.codeEspece === "POIRE" && n2.recolteSuivi.codeEspece === 'POMME' ) return 1;
        else return 0; 
      }
    );
  }

  private getReferentsEmails(idEntreprise: number): Array<string> {
    const entreprise = this.entreprises.find((entreprise: EntrepriseReferent) => entreprise.id === idEntreprise);
    return (entreprise && entreprise.referentRecolteList.length > 0)
      ? entreprise.referentRecolteList.map((contact: ContactReferent) => contact.mail)
      : [];
  }

  private addEntreprisesWithoutDeclaration(recoltes: Array<SaisieRecolte>): Array<SaisieRecolte> {
    let resultedRecoltes = recoltes;
    const declarationsAbsentes = this.entreprises.filter((entreprise: EntrepriseReferent) => 
      resultedRecoltes.find((recolte) => recolte.idEntreprise === entreprise.id) == null
    );
    declarationsAbsentes.forEach((entreprise: EntrepriseReferent) => 
      resultedRecoltes.push(Object.assign(new SaisieRecolte(), {
        idEntreprise: entreprise.id
      }))
    );
    return resultedRecoltes;
  }

}
