//#region import
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators, AbstractControl} from '@angular/forms';
import {
  NgbAccordion,
  NgbActiveModal, NgbModal,
  NgbPanelChangeEvent,
  NgbTabChangeEvent,
  NgbTabset
} from '@ng-bootstrap/ng-bootstrap';
import {AdvBootstrapLoaderService} from '@adv/bootstrap-loader';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Espece} from '../../../../../../../data/declaration/models/espece.model';
import {Variete} from '../../../../../../../data/declaration/models/variete.model';
import {RefSystemeIrrigation} from '../../../../../../../data/declaration/models/ref-systeme-irrigation.model';
import {RefProtectionGel} from '../../../../../../../data/declaration/models/ref-protection-gel.model';
import {RefProtectionGrele} from '../../../../../../../data/declaration/models/ref-protection-grele.model';
import {RefForme} from '../../../../../../../data/declaration/models/ref-forme.model';
import {RefCultureBio} from '../../../../../../../data/declaration/models/ref-culture-bio.model';
import {RefSituation} from '../../../../../../../data/declaration/models/ref-situation.model';
import {Localisation} from '../../../../../../../data/declaration/models/localisation.model';
import {LocalisationService} from '../../../../../../../data/declaration/services/parcellaire/localisation.service';

import {CaracteristiqueParcelle} from '../../../../../../../data/declaration/models/caracteristique-parcelle.model';
import {RefPorteGreffe} from '../../../../../../../data/declaration/models/ref-porte-greffe.model';
import {
  UniteCulturaleService
} from '../../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {UniteCulturalePost} from '../../../../../../../data/declaration/models/unite-culturale-post.model';
import {VarieteService} from '../../../../../../../data/declaration/services/parcellaire/variete.service';
import {DepartementService} from '../../../../../../../shared/services/dep-commune/departement.service';
import {CommuneService} from '../../../../../../../shared/services/dep-commune/commune.service';
import {Departement} from '../../../../../../../shared/models/departement.model';
import {Commune} from '../../../../../../../shared/models/commune.model';
import {UniteCulturale} from '../../../../../../../data/declaration/models/unite-culturale.model';
import {SelectFieldsColumnComponent} from '../select-fields-column/select-fields-column.component';
import {from} from 'rxjs';
import {AdvBootstrapModalService} from '@adv/bootstrap-modal';
import { RefPorteGreffeService } from 'src/app/data/declaration/services/parcellaire/ref-porte-greffe.service';
import { NavigationContext, SessionContext } from 'src/app/core/services/config/app.settings';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { DestinationVendange } from 'src/app/data/declaration/models/enums/destination-vendange.enum';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { RefStatutCode } from 'src/app/data/habilitation/models/enums/ref-statut-code.enum';
import { ActiviteCode } from 'src/app/data/habilitation/models/enums/activites-code.enum';
import { ProduitsService as ProduitsServiceHab  } from 'src/app/data/habilitation/services/produits/produits.service';
import { ProduitsService as ProduitsServiceDec  } from 'src/app/data/declaration/services/produits/produits.service';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { UniteCulturaleVigne } from 'src/app/data/declaration/models/unite-culturale-vigne.model';
import { RefModeConduite } from 'src/app/data/declaration/models/ref-mode-conduite.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
//#endregion

@Component({
  selector: 'app-add-bloc',
  templateUrl: './add-bloc.component.html',
  styleUrls: ['./add-bloc.component.scss']
})
export class AddBlocComponent implements OnInit {

//#region variable

  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  controleFormTracabilite: FormGroup;
  controleFormBotanique: FormGroup;
  controleFormCaracteristique: FormGroup;
  controleFormEquipementConduite: FormGroup;
  controleFormLocalisationGeo: FormGroup;
  controleFormDestination: FormGroup;
  localisations: Localisation[];
  departements: Departement[];
  communes: Commune[];

  @Input() refCultureBios: RefCultureBio[];
  @Input() refFormes: RefForme[];
  @Input() refProtectionGels: RefProtectionGel[];
  @Input() refProtectionGreles: RefProtectionGrele[];
  @Input() refSituations: RefSituation[];
  @Input() refSystemeIrrigations: RefSystemeIrrigation[];
  @Input() especes: Espece[];
  @Input() varietes: Variete[];
  @Input() varietesClone: Variete[];
  @Input() cahiers: Cahier[];
  @Input() produitIdentifies: Produit[];
  @Input() produitDestinations: Produit[];
  @Input() refPorteGreffes: RefPorteGreffe[];
  @Input() uniteCulturale: UniteCulturale;
  @Input() modifierBlocBool: boolean;
  @Input() idEntreprise: number;  
  @Input() refModeConduites: RefModeConduite[];


  surfaceHa: number;
  idTab: number;
  idTabMAX: number;
  isButtonVisible: boolean;
  hiddenButton = false;
  localisationCheck: Localisation;
  destinataire: Entreprise
  manualChange = false;
  updateLocalisation = false;
  destinationV: any;

  destinations = DestinationVendange;
  enumKeys = Object.keys(DestinationVendange);

  @ViewChild('shadow') shadow: any;
  headers = [];
  private tabSet: NgbTabset;
  public maxDistance = 999;
  public minDistance = 0;
  public maxDigitsDate = 9999;
  public minDigitsDate = 1000;

  //#endregion

  //#region champs form

  get departement() {
    return this.controleFormLocalisationGeo.get('departement');
  }

  get codePostal() {
    return this.controleFormLocalisationGeo.get('codePostal');
  }

  get commune() {
    return this.controleFormLocalisationGeo.get('commune');
  }

  get lieuDit() {
    return this.controleFormLocalisationGeo.get('lieuDit');
  }

  get refCadastrale() {
    return this.controleFormLocalisationGeo.get('refCadastrale');
  }

  get surfCadastrale() {
    return this.controleFormLocalisationGeo.get('surfCadastrale');
  }

  get situationLoca() {
    return this.controleFormLocalisationGeo.get('situation');
  }

  get systemeIrrigationLoca() {
    return this.controleFormEquipementConduite.get('systemeIrrigation');
  }

  get protectionGelLoca() {
    return this.controleFormEquipementConduite.get('protectionGel');
  }

  get protectionGreleLoca() {
    return this.controleFormEquipementConduite.get('protectionGrele');
  }

  get cultureBioLoca() {
    return this.controleFormEquipementConduite.get('cultureBio');
  }

  get formeLoca() {
    return this.controleFormEquipementConduite.get('forme');
  }

  get numBloc() {
    return this.controleFormTracabilite.get('numBloc');
  }

  get numTracabilite() {
    return this.controleFormTracabilite.get('numTracabilite');
  }

  get codeParcelle() {
    return this.controleFormTracabilite.get('codeParcelle');
  }

  get confusion() {
    return this.controleFormCaracteristique.get('confusion');
  }

  get espece() {
    return this.controleFormBotanique.get('espece');
  }

  get variete() {
    return this.controleFormBotanique.get('variete');
  }

  get clone() {
    return this.controleFormBotanique.get('clone');
  }

  get porteGreffe() {
    return this.controleFormBotanique.get('porteGreffe');
  }

  get anneePlantation() {
    return this.controleFormBotanique.get('anneePlantation');
  }

  get anneePremiereFeuille() {
    return this.controleFormBotanique.get('anneePremiereFeuille');
  }

  get anneeSurgreffage() {
    return this.controleFormBotanique.get('anneeSurgreffage');
  }

  get distanceInterRang() {
    return this.controleFormBotanique.get('distanceInterRang');
  }

  get distanceIntraRang() {
    return this.controleFormBotanique.get('distanceIntraRang');
  }  

  get surfacePlantee() {
    return this.controleFormBotanique.get('surfacePlantee');
  }

  get manquant() {
    return this.controleFormBotanique.get('manquant');
  }
  
  get densite() {
    return this.controleFormBotanique.get('densite');
  }

  get cahier() {
    return this.controleFormDestination.get('cahier');
  }

  get identifie() {
    return this.controleFormDestination.get('identifie');
  }

  get destinationVin() {
    return this.controleFormDestination.get('destinationV');
  }

  get destination() {
    return this.controleFormDestination.get('destination');
  }

  get surfaceEngagee() {
    return this.controleFormDestination.get('surfaceEngagee');
  }

  get nbArbre() {
    return this.controleFormBotanique.get('nbArbre');
  }

  get situationBloc() {
    return this.controleFormCaracteristique.get('situation');
  }

  get systemeIrrigationBloc() {
    return this.controleFormCaracteristique.get('systemeIrrigation');
  }

  get protectionGelBloc() {
    return this.controleFormCaracteristique.get('protectionGel');
  }

  get protectionGreleBloc() {
    return this.controleFormCaracteristique.get('protectionGrele');
  }

  get cultureBioBloc() {
    return this.controleFormCaracteristique.get('cultureBio');
  }

  get formeBloc() {
    return this.controleFormCaracteristique.get('forme');
  }

  get modeConduite() {
    return this.controleFormCaracteristique.get('modeConduite');
  }

  get derogationTaille() {
    return this.controleFormCaracteristique.get('derogationTaille');
  }

  //#endregion

  public get isAnpp(): boolean {
    return (SessionContext.get('codeDomaine') === 'ANPP');
  }

  public get codeDomaine(): string {
    return SessionContext.get('codeDomaine') ;
  }

  private get idOperateur(): number {
    if (NavigationContext.has('idOperateur')) {
      return NavigationContext.get('idOperateur');
    } else {
      return SessionContext.get('idIntervenant');
    }
  }

  private get idOrganisme(): number { 
    return SessionContext.get('idOrganisme'); 
  }

  constructor(
    public readonly modal: NgbActiveModal,
    public readonly modalSelectField: NgbModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly localisationService: LocalisationService,
    private readonly departementService: DepartementService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly varieteService: VarieteService,
    private readonly communeService: CommuneService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly refPorteGreffeService: RefPorteGreffeService,
    private readonly produitServiceHab: ProduitsServiceHab,    
    private readonly produitServiceDec: ProduitsServiceDec,
    private readonly cahiersService: CahiersService,
    private readonly entrepriseService: EntreprisesService
  ) {
  }

  ngOnInit() {
    this.loadHeader();
    this.idTab = 1;
    this.idTabMAX = 6;
    this.isButtonVisible = true;
    this.initForms();
    this.loadData();
  }

  initForms() {
    this.controleFormTracabilite = this.fb.group({
      numBloc: [undefined, this.numBlocValidator.bind(this)],
      numTracabilite: [undefined, this.numTracabiliteValidator.bind(this)],
      codeParcelle: [undefined, this.codeParcelleValidator.bind(this)]
    });
    this.controleFormBotanique = this.fb.group({
      espece: [undefined, this.conditionnelValidators('ANPP')],
      variete: [undefined, Validators.required],
      clone: [undefined],
      porteGreffe: [undefined],
      anneePlantation: [undefined, [this.anneePlantationValidator.bind(this), Validators.max(this.maxDigitsDate), Validators.min(this.minDigitsDate)]],
      anneePremiereFeuille: [undefined, [this.anneePremiereFeuilleValidator.bind(this), Validators.max(this.maxDigitsDate), Validators.min(this.minDigitsDate)]],
      anneeSurgreffage: [undefined, [this.anneeSurgreffageValidator.bind(this), Validators.max(this.maxDigitsDate), Validators.min(this.minDigitsDate)]],
      distanceInterRang: [undefined, [Validators.required, Validators.max(this.maxDistance), Validators.min(this.minDistance)]],
      distanceIntraRang: [undefined, [Validators.required, Validators.max(this.maxDistance), Validators.min(this.minDistance)]],
      nbArbre: [undefined, this.conditionnelValidators('ANPP')],
      surfacePlantee: [undefined, this.conditionnelValidators('VIN')], 
      manquant: [undefined, this.conditionnelValidators('VIN')], 
      densite: [undefined, this.conditionnelValidators('VIN')], 
    });
    this.controleFormCaracteristique = this.fb.group({
      systemeIrrigation: [undefined],
      protectionGel: [undefined],
      protectionGrele: [undefined],
      forme: [undefined],
      cultureBio: [undefined],
      situation: [undefined],
      confusion: [undefined],
      modeConduite: [undefined],
      derogationTaille: [undefined]
    });
    this.controleFormLocalisationGeo = this.fb.group({
      departement: [undefined, Validators.required],
      codePostal: [undefined, Validators.required],
      commune: [undefined, Validators.required],
      lieuDit: [undefined, this.lieuDitValidator.bind(this)],
      refCadastrale: [undefined, this.refCadastraleValidator.bind(this)],
      surfCadastrale: [undefined, [this.conditionnelValidators('VIN'),Validators.pattern('^-?[0-9]+(\.[0-9]+)?$')]],
      situation: [undefined, Validators.required]
    });
    this.controleFormEquipementConduite = this.fb.group({
      systemeIrrigation: [undefined],
      protectionGel: [undefined],
      protectionGrele: [undefined],
      forme: [undefined],
      cultureBio: [undefined]
    });
    this.controleFormDestination = this.fb.group({
      destinationVin: [undefined],
      destination: [undefined],
      identifie: [undefined,this.conditionnelValidators('VIN')],
      cahier: [undefined],
      surfaceEngagee: [undefined,this.conditionnelValidators('VIN')]
    });
  }

//#region Validator

  private lieuDitValidator(control: FormControl) {
    return (this.controleFormLocalisationGeo && !this.refCadastrale.value && !control.value) ?
      {invalid: true} : null;
  }

  private refCadastraleValidator(control: FormControl) {
    return (this.controleFormLocalisationGeo && !this.lieuDit.value && !control.value) ?
      {invalid: true} : null;
  }

  private numBlocValidator(control: AbstractControl) {
    return (this.controleFormTracabilite && ((!this.numTracabilite.value && !this.codeParcelle.value) && !control.value)) ?
      {invalid: true} : null;
  }

  private numTracabiliteValidator(control: AbstractControl) {
    return (this.controleFormTracabilite && ((!this.numBloc.value && !this.codeParcelle.value) && !control.value) && this.isAnpp) ?
      {invalid: true} : null;
  }

  private codeParcelleValidator(control: AbstractControl) {
    return (this.controleFormTracabilite && ((!this.numBloc.value && !this.numTracabilite.value) && !control.value) && this.isAnpp) ?
      {invalid: true} : null;
  }

  private anneePlantationValidator(control: FormControl) {
    return (this.controleFormBotanique && ((!this.anneePremiereFeuille.value && !this.anneeSurgreffage.value) && !control.value)) ?
      {invalid: true} : null;
  }

  private anneePremiereFeuilleValidator(control: FormControl) {
    return (this.controleFormBotanique && ((!this.anneePlantation.value && !this.anneeSurgreffage.value) && !control.value) && this.isAnpp) ?
      {invalid: true} : null;
  }

  private anneeSurgreffageValidator(control: FormControl) {
    return (this.controleFormBotanique && ((!this.anneePremiereFeuille.value && !this.anneePlantation.value) && !control.value) && this.isAnpp) ?
      {invalid: true} : null;
  }

  conditionnelValidators(requireDomaine: string) {
    return (control) => {
      if (this.codeDomaine === requireDomaine) {
        return Validators.required(control);
      } else {
        return null;
      }
    };
  }

//#endregion
  

  changeLocalisationGeo() {
    if (this.controleFormLocalisationGeo) {
      this.lieuDit.setErrors(null);
      this.lieuDit.updateValueAndValidity();
      this.refCadastrale.setErrors(null);
      this.refCadastrale.updateValueAndValidity();
      this.surfCadastrale.setErrors(null);
      this.surfCadastrale.updateValueAndValidity();
    }
  }

  changeBotanique() {
    if (this.controleFormBotanique) {
      this.anneePremiereFeuille.setErrors(null);
      this.anneePremiereFeuille.updateValueAndValidity();
      this.anneeSurgreffage.setErrors(null);
      this.anneeSurgreffage.updateValueAndValidity();
      this.anneePlantation.setErrors(null);
      this.anneePlantation.updateValueAndValidity();
    }
  }

  changeTrancabilite() {
    if (this.controleFormTracabilite) {
      this.numBloc.setErrors(null);
      this.numBloc.updateValueAndValidity();
      this.numTracabilite.setErrors(null);
      this.numTracabilite.updateValueAndValidity();
      this.codeParcelle.setErrors(null);
      this.codeParcelle.updateValueAndValidity();
    }
  }

  changeDestination() {
    if (this.controleFormDestination) {
      this.surfaceEngagee.setErrors(null);
      this.surfaceEngagee.updateValueAndValidity();
    }
  }

  loadData() {
    this.localisationService.getLocalisationsEntreprise(this.idEntreprise).pipe(this.loaderService.operator()).subscribe((localisations) => {
      this.localisations = localisations;
      
      if(!this.isAnpp){
        this.cahiersService.getCahiersByHabilitation(this.idOperateur, (this.idOrganisme)?this.idOrganisme:0, RefStatutCode.Habilite, ActiviteCode.VIN_PROD_RAISIN).pipe(this.loaderService.operator())
        .subscribe((cahiers) => {
          this.cahiers = cahiers;
          if (this.modifierBlocBool) {
          this.manualChange = true;
          this.loadUniteCulturale();
          }
        });
      }else{
        if (this.modifierBlocBool) {
          this.manualChange = true;
          this.loadUniteCulturale();
          }          
      }    
    });
    this.departementService.getLocalisationDepartements().pipe(this.loaderService.operator()).subscribe((departements) => {
      this.departements = departements;
    });

    if(!this.isAnpp){
      this.changeValueEspece();
    }

  }

  loadUniteCulturale() {
    this.localisationCheck = this.localisations.find(loc => loc.id === this.uniteCulturale.localisation.id);
    this.numBloc.setValue(this.uniteCulturale.numBlocFruitier);
    this.numTracabilite.setValue(this.uniteCulturale.numTracabilite);
    this.codeParcelle.setValue(this.uniteCulturale.codeParcelle);
    this.espece.setValue(this.especes.find(espece => espece.id === this.uniteCulturale.espece.id));
    
    if (this.uniteCulturale.variete.varieteClone != null) {
          this.variete.setValue(this.varietes.find(variete => variete.id === this.uniteCulturale.variete.varieteClone.id));
          this.varieteService.getClonesByVariete(this.variete.value.id).pipe(this.loaderService.operator()).subscribe((clones) => {
            this.varietesClone = clones;
            this.varietesClone.sort((a, b) => {
              return a.libelle.localeCompare(b.libelle);
            });
            this.clone.setValue(this.varietesClone.find(clone => clone.id === this.uniteCulturale.variete.id));
          });
     } else {
      
          this.variete.setValue(this.varietes.find(variete => variete.id === this.uniteCulturale.variete.id));

          if(this.isAnpp){
            this.changeValueVariete();
          }
    }
   
    if (this.uniteCulturale.porteGreffe != null) {
      this.porteGreffe.setValue(this.refPorteGreffes.find(porteGreffe => porteGreffe.id === this.uniteCulturale.porteGreffe.id));
    }
    this.anneePlantation.setValue(this.uniteCulturale.anneePlantation);
    this.anneeSurgreffage.setValue(this.uniteCulturale.anneeSurgreffage);
    this.anneePremiereFeuille.setValue(this.uniteCulturale.anneePremiereFeuille);
    this.distanceInterRang.setValue(this.uniteCulturale.distanceInterRang);
    this.distanceIntraRang.setValue(this.uniteCulturale.distanceIntraRang);
    this.nbArbre.setValue(this.uniteCulturale.nbArbre);
    this.calculeSurface();
    if (this.uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation != null) {
      this.systemeIrrigationBloc.setValue(this.refSystemeIrrigations.find(iri => iri.id === this.uniteCulturale.caracteristiqueParcelle.refSystemeIrrigation.id));
    }
    if (this.uniteCulturale.caracteristiqueParcelle.refProtectionGel != null) {
      this.protectionGelBloc.setValue(this.refProtectionGels.find(refgel => refgel.id === this.uniteCulturale.caracteristiqueParcelle.refProtectionGel.id));
    }
    if (this.uniteCulturale.caracteristiqueParcelle.refProtectionGrele != null) {
      this.protectionGreleBloc.setValue(this.refProtectionGreles.find(refGrele => refGrele.id === this.uniteCulturale.caracteristiqueParcelle.refProtectionGrele.id));
    }
    if (this.uniteCulturale.caracteristiqueParcelle.refCultureBio != null) {
      this.cultureBioBloc.setValue(this.refCultureBios.find(refCultBio => refCultBio.id === this.uniteCulturale.caracteristiqueParcelle.refCultureBio.id));
    }
    if (this.uniteCulturale.caracteristiqueParcelle.refForme != null) {
      this.formeBloc.setValue(this.refFormes.find(refForme => refForme.id === this.uniteCulturale.caracteristiqueParcelle.refForme.id));
    }
    if (this.uniteCulturale.caracteristiqueParcelle.refSituation != null) {
      this.situationBloc.setValue(this.refSituations.find(refSituation => refSituation.id === this.uniteCulturale.caracteristiqueParcelle.refSituation.id));
    }
    this.confusion.setValue(this.uniteCulturale.confusion);

    if(!this.isAnpp){
      this.produitIdentifies = [];
        this.produitServiceDec.getProduitsById([this.uniteCulturale.uniteCulturaleVigne.idProduitIdentifie]).subscribe(produitIdentifie =>{
            this.produitServiceHab.getProduitsByCdcs(this.idOrganisme, [produitIdentifie[0].idCahier]).subscribe(produits => {
            this.produitIdentifies = produits;     
            this.identifie.setValue(this.produitIdentifies.find(produit => produit.id === this.uniteCulturale.uniteCulturaleVigne.idProduitIdentifie));
          
            this.cahier.setValue(this.cahiers.find(cah => cah.id === this.identifie.value.idCahier ))

            this.produitServiceDec.getProduitsByProduitOrigine(this.identifie.value.id).subscribe(produits => {
        
              this.controleFormDestination.disable();
              this.produitDestinations = produits;
              this.produitDestinations.push(this.identifie.value);
              this.controleFormDestination.enable();  
              this.destination.setValue(this.produitDestinations.find(produit => produit.id === this.uniteCulturale.uniteCulturaleVigne.idProduitDestination));      
            });

            
          });

        });
        
     

      if (this.uniteCulturale.uniteCulturaleVigne.idModeConduite != null) {
        this.modeConduite.setValue(this.refModeConduites.find(produit => produit.id === this.uniteCulturale.uniteCulturaleVigne.idModeConduite));
      }
      this.surfacePlantee.setValue(this.uniteCulturale.uniteCulturaleVigne.surfacePlantee);
      this.surfaceEngagee.setValue(this.uniteCulturale.uniteCulturaleVigne.surfaceEngagee);
      this.densite.setValue(this.uniteCulturale.uniteCulturaleVigne.densite);
      this.manquant.setValue(this.uniteCulturale.uniteCulturaleVigne.manquant);
      this.derogationTaille.setValue(this.uniteCulturale.uniteCulturaleVigne.derogationTaille);
      this.destination.setValue(this.uniteCulturale.uniteCulturaleVigne.destinationVendange);
      if(this.uniteCulturale.uniteCulturaleVigne.idEntrepriseDestination){
       this.entrepriseService.getEntreprise(this.uniteCulturale.uniteCulturaleVigne.idEntrepriseDestination).subscribe((entreprise) => {
          this.destinataire = entreprise;
       });
      }
    }

  }

  cancel() {
    this.modal.close();
  }

  submit() {
    if (this.idTab === this.idTabMAX) {
      if (this.localisationCheck != null) {
        if (this.controleFormTracabilite.valid && this.controleFormBotanique.valid && (this.controleFormDestination.valid || this.isAnpp)) {
          const uniteCulturale = new UniteCulturalePost();
          const caracteristiqueParcelle = new CaracteristiqueParcelle();
          if (this.numBloc.value) {
            uniteCulturale.numBlocFruitier = this.numBloc.value;
          }
          if (this.numTracabilite.value) {
            uniteCulturale.numTracabilite = this.numTracabilite.value;
          }
          if (this.codeParcelle.value) {
            uniteCulturale.codeParcelle = this.codeParcelle.value;
          }
          if (this.espece.value){
            uniteCulturale.espece = this.espece.value.id;
          }
          else{
            uniteCulturale.espece = this.especes[0].id;
          }
          if (this.clone.value) {
            uniteCulturale.variete = this.clone.value.id;
          } else {
            uniteCulturale.variete = this.variete.value.id;
          }
          if (this.porteGreffe.value) {
            uniteCulturale.porteGreffe = this.porteGreffe.value.id;
          }
          if (this.anneePlantation.value) {
            uniteCulturale.anneePlantation = this.anneePlantation.value;
          }
          if (this.anneePremiereFeuille.value) {
            uniteCulturale.anneePremiereFeuille = this.anneePremiereFeuille.value;
          }
          if (this.anneeSurgreffage.value) {
            uniteCulturale.anneeSurgreffage = this.anneeSurgreffage.value;
          }
          uniteCulturale.distanceInterRang = this.distanceInterRang.value;
          uniteCulturale.distanceIntraRang = this.distanceIntraRang.value;

          if (this.nbArbre.value) {
            uniteCulturale.nbArbre = this.nbArbre.value;
          } else uniteCulturale.nbArbre = 0;

          if (this.systemeIrrigationBloc.value) {
            caracteristiqueParcelle.refSystemeIrrigation = this.systemeIrrigationBloc.value;
          }
          if (this.protectionGelBloc.value) {
            caracteristiqueParcelle.refProtectionGel = this.protectionGelBloc.value;
          }
          if (this.protectionGreleBloc.value) {
            caracteristiqueParcelle.refProtectionGrele = this.protectionGreleBloc.value;
          }
          if (this.cultureBioBloc.value) {
            caracteristiqueParcelle.refCultureBio = this.cultureBioBloc.value;
          }
          if (this.formeBloc.value) {
            caracteristiqueParcelle.refForme = this.formeBloc.value;
          }
          if (this.situationBloc.value) {
            caracteristiqueParcelle.refSituation = this.situationBloc.value;
          }
          if (this.confusion.value) {
            uniteCulturale.confusion = this.confusion.value;
          }

          if(!this.isAnpp){
            const uniteCulturaleVigne = new UniteCulturaleVigne();

            if(this.uniteCulturale){
            uniteCulturaleVigne.id = this.uniteCulturale.uniteCulturaleVigne.id;
            }
            uniteCulturaleVigne.surfacePlantee = this.surfacePlantee.value;
            uniteCulturaleVigne.surfaceEngagee = this.surfaceEngagee.value;

            uniteCulturaleVigne.idProduitIdentifie = this.identifie.value.id;
            
            if(this.destination.value){
              uniteCulturaleVigne.idProduitDestination = this.destination.value.id;
            } else {
              uniteCulturaleVigne.idProduitDestination = this.identifie.value.id;
            }

            if(this.destinationV){
              uniteCulturaleVigne.destinationVendange = this.destinationV;
            }
            if(this.destinataire){
              uniteCulturaleVigne.idEntrepriseDestination = this.destinataire.id;
            }

            if(this.manquant.value){
              uniteCulturaleVigne.manquant = this.manquant.value;
            }
            if(this.modeConduite.value){
              uniteCulturaleVigne.idModeConduite = this.modeConduite.value.id;
            }
            if(this.derogationTaille.value){
              uniteCulturaleVigne.derogationTaille = this.derogationTaille.value;
            }

            uniteCulturaleVigne.densite = this.densite.value;

            uniteCulturale.uniteCulturaleVigne = uniteCulturaleVigne;
          }

          uniteCulturale.caracteristiqueParcelle = caracteristiqueParcelle;
          uniteCulturale.localisation = this.localisationCheck.id;
          if (this.modifierBlocBool) {

            uniteCulturale.caracteristiqueParcelle.id = this.uniteCulturale.caracteristiqueParcelle.id;
            this.uniteCulturaleService.modifierUniteCulturale(this.idEntreprise, this.uniteCulturale.id, uniteCulturale).subscribe(() => {
              this.modal.close();
            });
          } else {
            this.uniteCulturaleService.creerUniteCulturale(this.idEntreprise, uniteCulturale).subscribe(() => {
              this.modal.close();
            });
          }
        } else {
          if (this.controleFormTracabilite.valid) {
            this.tabSet.select('3');
            this.controleFormBotanique.markAsTouched();
          } else if(this.controleFormBotanique.valid) {
            this.tabSet.select('2');
            this.controleFormTracabilite.markAsTouched();
          } else if(!this.isAnpp && !this.controleFormBotanique.valid) {
            this.tabSet.select('5');
            this.controleFormBotanique.markAsTouched();
          }
        }
      } else {
        this.tabSet.select('1');
        this.modalsService.alert(this.translate.instant(`page.inventaire-verger.modals.add-bloc.selectLocalisationRequired`), null, {windowClass: 'anpp'}).then(() => {
        });

      }
    } else if (this.updateLocalisation) {
      if (this.controleFormLocalisationGeo.valid) {
        const localisation = new Localisation();
        const caracteristiqueParcelle = new CaracteristiqueParcelle();
        localisation.departement = this.departement.value.code;
        localisation.codePostal = this.codePostal.value;
        localisation.commune = this.commune.value.nom;
        if (this.lieuDit.value) {
          localisation.lieuDit = this.lieuDit.value;
        }
        if (this.refCadastrale.value) {
          localisation.referenceCadastrale = this.refCadastrale.value;
        }
        if (this.surfCadastrale.value) {
          localisation.surfaceCadastrale = this.surfCadastrale.value;
        }
        caracteristiqueParcelle.refSituation = this.situationLoca.value;
        if (this.systemeIrrigationLoca.value) {
          caracteristiqueParcelle.refSystemeIrrigation = this.systemeIrrigationLoca.value;
        }
        if (this.protectionGelLoca.value) {
          caracteristiqueParcelle.refProtectionGel = this.protectionGelLoca.value;
        }
        if (this.protectionGreleLoca.value) {
          caracteristiqueParcelle.refProtectionGrele = this.protectionGreleLoca.value;
        }
        if (this.cultureBioLoca.value) {
          caracteristiqueParcelle.refCultureBio = this.cultureBioLoca.value;
        }
        if (this.formeLoca.value) {
          caracteristiqueParcelle.refForme = this.formeLoca.value;
        }
        localisation.caracteristiqueParcelle = caracteristiqueParcelle;
        this.localisationService.modifierLocalisationEntreprise(this.idEntreprise, this.localisationCheck.id, localisation).pipe(this.loaderService.operator()).subscribe(() => {
          this.updateLocalisation = false;
          this.ngOnInit();
          this.shadow.toggle('panelListeLocalisation');
        });
      }
    } else {
      if (this.controleFormLocalisationGeo.valid) {
        const localisation = new Localisation();
        const caracteristiqueParcelle = new CaracteristiqueParcelle();
        localisation.departement = this.departement.value.code;
        localisation.codePostal = this.codePostal.value;
        localisation.commune = this.commune.value.nom;
        if (this.lieuDit.value) {
          localisation.lieuDit = this.lieuDit.value;
        }
        if (this.refCadastrale.value) {
          localisation.referenceCadastrale = this.refCadastrale.value;
        }
        if (this.surfCadastrale.value) {
          localisation.surfaceCadastrale = this.surfCadastrale.value;
        }
        caracteristiqueParcelle.refSituation = this.situationLoca.value;
        if (this.systemeIrrigationLoca.value) {
          caracteristiqueParcelle.refSystemeIrrigation = this.systemeIrrigationLoca.value;
        }
        if (this.protectionGelLoca.value) {
          caracteristiqueParcelle.refProtectionGel = this.protectionGelLoca.value;
        }
        if (this.protectionGreleLoca.value) {
          caracteristiqueParcelle.refProtectionGrele = this.protectionGreleLoca.value;
        }
        if (this.cultureBioLoca.value) {
          caracteristiqueParcelle.refCultureBio = this.cultureBioLoca.value;
        }
        if (this.formeLoca.value) {
          caracteristiqueParcelle.refForme = this.formeLoca.value;
        }
        localisation.caracteristiqueParcelle = caracteristiqueParcelle;
        this.localisationService.creerLocalisationEntreprise(this.idEntreprise, localisation).subscribe(() => {
          this.ngOnInit();
          this.shadow.toggle('panelListeLocalisation');
        });
      } else {
        this.tabSet.select('localisationGeo');
        this.controleFormLocalisationGeo.markAsTouched();
      }
    }
  }

  panelShadow($event: NgbPanelChangeEvent, shadow: NgbAccordion) {
    const activePanelId = $event.panelId;
    const activePanelElem = document.getElementById(activePanelId);
    if (activePanelId === 'panelAjouterLocalisation') {
      this.hiddenButton = true;
    } else {
      this.hiddenButton = false;
      this.isButtonVisible = true;
    }
    if (!shadow.isExpanded(activePanelId)) {
      activePanelElem.parentElement.classList.add('open');
    }
    if (shadow.isExpanded('panelAjouterLocalisation')) {
      this.isButtonVisible = true;
    }

  }

  changeValueCodePostal(event): void {
    const codePostal = event.target.value;
    if (codePostal.length === 2) {
      this.departement.setValue(this.departements[this.departements.findIndex(dep => dep.code === this.codePostal.value)]);
    }
    if (codePostal.length === 5) {
      this.communeService.getCommunes(codePostal).pipe(this.loaderService.operator()).subscribe((communes) => {
        this.communes = communes;
      });
    }
    if (codePostal.length < 5) {
      this.communes = [];
    }
  }

  changeValueEspece(): void {
    if(this.isAnpp){
    this.varieteService.getVarietesEspece(this.espece.value.id).pipe(this.loaderService.operator()).subscribe((varietes) => {
      this.varietes = varietes;
      this.varietes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
    this.refPorteGreffeService.getRefPorteGreffeByIdEspece(this.espece.value.id).pipe(this.loaderService.operator()).subscribe((refPorteGreffes) => {
      this.refPorteGreffes = refPorteGreffes;
      this.refPorteGreffes.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
  }
  else{
    if(this.especes){
        this.varieteService.getVarietesEspeceByIntervenant(this.especes[0].id, this.idOperateur).pipe(this.loaderService.operator()).subscribe((varietes) => {
          
            this.varietes = varietes;
            this.varietes.sort((a, b) => {
              return a.libelle.localeCompare(b.libelle);
            });
        });
        this.refPorteGreffeService.getRefPorteGreffeByIdEspece(this.especes[0].id).pipe(this.loaderService.operator()).subscribe((refPorteGreffes) => {
          this.refPorteGreffes = refPorteGreffes;      
            this.refPorteGreffes.sort((a, b) => {
              return a.libelle.localeCompare(b.libelle);
            });
        });
      }
    }
  }

  calculeSurface(): void {

    switch(this.codeDomaine){
      case 'ANPP':
        this.surfaceHa = ((this.distanceInterRang.value * this.distanceIntraRang.value) * this.nbArbre.value) / 10000;
        break;
      case 'VIN':
        this.controleFormBotanique.patchValue({densite: (10000 / (this.distanceInterRang.value * this.distanceIntraRang.value)).toFixed(2)});
        break;
    }
  
  }

  beforeChange($event: NgbTabChangeEvent, tabSet: NgbTabset) {
    this.tabSet = tabSet;
    this.isButtonVisible = true;
    this.hiddenButton = false;
    this.idTab = Number($event.nextId);
    if (this.idTab === this.idTabMAX) {
      this.isButtonVisible = false;
    }
  }

  onTabChange(tabSet: NgbTabset): void {
    this.tabSet = tabSet;
    if (this.idTab < this.idTabMAX) {
      this.idTab++;
    }
    tabSet.select(this.idTab.toString());
  }

  onCheckboxChange(localisation: Localisation, $event: any) {
    this.localisationCheck = localisation;
    if (!$event.target.checked && this.localisationCheck === localisation) {
      this.localisationCheck = null;
      if (!this.manualChange) {
        this.systemeIrrigationBloc.setValue(null);
        this.protectionGelBloc.setValue(null);
        this.protectionGreleBloc.setValue(null);
        this.cultureBioBloc.setValue(null);
        this.formeBloc.setValue(null);
        this.situationBloc.setValue(null);
      }
    } else if (!this.manualChange) {
      if (this.localisationCheck.caracteristiqueParcelle.refSystemeIrrigation != null) {
        this.systemeIrrigationBloc.setValue(this.refSystemeIrrigations.find(iri => iri.id === this.localisationCheck.caracteristiqueParcelle.refSystemeIrrigation.id));
      }
      if (this.localisationCheck.caracteristiqueParcelle.refProtectionGel != null) {
        this.protectionGelBloc.setValue(this.refProtectionGels.find(refgel => refgel.id === this.localisationCheck.caracteristiqueParcelle.refProtectionGel.id));
      }
      if (this.localisationCheck.caracteristiqueParcelle.refProtectionGrele != null) {
        this.protectionGreleBloc.setValue(this.refProtectionGreles.find(refGrele => refGrele.id === this.localisationCheck.caracteristiqueParcelle.refProtectionGrele.id));
      }
      if (this.localisationCheck.caracteristiqueParcelle.refCultureBio != null) {
        this.cultureBioBloc.setValue(this.refCultureBios.find(refCultBio => refCultBio.id === this.localisationCheck.caracteristiqueParcelle.refCultureBio.id));
      }
      if (this.localisationCheck.caracteristiqueParcelle.refForme != null) {
        this.formeBloc.setValue(this.refFormes.find(refForme => refForme.id === this.localisationCheck.caracteristiqueParcelle.refForme.id));
      }
      if (this.localisationCheck.caracteristiqueParcelle.refSituation != null) {
        this.situationBloc.setValue(this.refSituations.find(refSituation => refSituation.id === this.localisationCheck.caracteristiqueParcelle.refSituation.id));
      }
    }
  }

  modifierLocalisation(localisation: Localisation) {
    this.updateLocalisation = true;
    this.localisationCheck = localisation;
    this.departement.setValue(this.departements[this.departements.findIndex(dep => dep.code === this.localisationCheck.departement)]);
    this.codePostal.setValue(this.localisationCheck.codePostal);
    this.communeService.getCommunes(this.localisationCheck.codePostal).pipe(this.loaderService.operator()).subscribe((communes) => {
      this.communes = communes;
      this.commune.setValue(this.communes.find(com => com.nom === this.localisationCheck.commune));
    });

    this.lieuDit.setValue(this.localisationCheck.lieuDit);
    this.refCadastrale.setValue(this.localisationCheck.referenceCadastrale);
    if (this.localisationCheck.caracteristiqueParcelle.refSystemeIrrigation != null) {
      this.systemeIrrigationLoca.setValue(this.refSystemeIrrigations.find(iri => iri.id === this.localisationCheck.caracteristiqueParcelle.refSystemeIrrigation.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refProtectionGel != null) {
      this.protectionGelLoca.setValue(this.refProtectionGels.find(refgel => refgel.id === this.localisationCheck.caracteristiqueParcelle.refProtectionGel.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refProtectionGrele != null) {
      this.protectionGreleLoca.setValue(this.refProtectionGreles.find(refGrele => refGrele.id === this.localisationCheck.caracteristiqueParcelle.refProtectionGrele.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refCultureBio != null) {
      this.cultureBioLoca.setValue(this.refCultureBios.find(refCultBio => refCultBio.id === this.localisationCheck.caracteristiqueParcelle.refCultureBio.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refForme != null) {
      this.formeLoca.setValue(this.refFormes.find(refForme => refForme.id === this.localisationCheck.caracteristiqueParcelle.refForme.id));
    }
    if (this.localisationCheck.caracteristiqueParcelle.refSituation != null) {
      this.situationLoca.setValue(this.refSituations.find(refSituation => refSituation.id === this.localisationCheck.caracteristiqueParcelle.refSituation.id));
    }
    this.shadow.toggle('panelAjouterLocalisation');

  }

  supprimerLocalisation(localisation: Localisation) {
    if (localisation.nbBloc > 0) {
      this.modalsService.error(this.translate.instant(`page.inventaire-verger.modals.add-bloc.deleteLocalisationError`), null, {windowClass: 'anpp'}).then(() => {
      });
    } else {
      this.localisationService.supprimerLocalisationEntreprise(this.idEntreprise, localisation.id).pipe(this.loaderService.operator()).subscribe(() => {
        this.loadData();
      });
    }
  }

  changeValueVariete() {
    this.varieteService.getClonesByVariete(this.variete.value.id).pipe(this.loaderService.operator()).subscribe((clones) => {
      this.varietesClone = clones;
      this.varietesClone.sort((a, b) => {
        return a.libelle.localeCompare(b.libelle);
      });
    });
  }

  changeValueDestination(value: string){
    this.destinationV = value;
  }

  private loadHeader() {
    if(this.isAnpp){
    this.headers = [
      {name: 'departement', label: this.translate.instant('page.inventaire-verger.departement'), checked: true},
      {name: 'commune', label: this.translate.instant('page.inventaire-verger.commune'), checked: true},
      {name: 'lieuDit', label: this.translate.instant('page.inventaire-verger.lieuDit'), checked: true},
      {name: 'refCadastrale', label: this.translate.instant('page.inventaire-verger.refCadastrale'), checked: true},
      {name: 'situation', label: this.translate.instant('page.inventaire-verger.situation'), checked: true},
      {
        name: 'systemeIrrigation',
        label: this.translate.instant('page.inventaire-verger.systemeIrrigation'),
        checked: true
      },
      {name: 'protectionGrele', label: this.translate.instant('page.inventaire-verger.protectionGrele'), checked: true},
      {name: 'protectionGel', label: this.translate.instant('page.inventaire-verger.protectionGel'), checked: false},
      {name: 'forme', label: this.translate.instant('page.inventaire-verger.forme'), checked: false},
      {name: 'cultureBio', label: this.translate.instant('page.inventaire-verger.cultureBio'), checked: true},
      {
        name: 'nbBloc',
        label: this.translate.instant('page.inventaire-verger.modals.add-bloc.nbBlocsLoca'),
        checked: true
      },
    ];
  }
  else{
    this.headers = [
      {name: 'departement', label: this.translate.instant('page.inventaire-verger.departement'), checked: true},
      {name: 'commune', label: this.translate.instant('page.inventaire-verger.commune'), checked: true},
      {name: 'lieuDit', label: this.translate.instant('page.inventaire-verger.lieuDit'), checked: true},
      {name: 'refCadastrale', label: this.translate.instant('page.inventaire-verger.refCadastrale'), checked: true},   
      {name: 'surfCadastrale', label: this.translate.instant('page.inventaire-verger.surfCadastrale'), checked: true},    
      {
        name: 'nbBloc',
        label: this.translate.instant('page.inventaire-verger.modals.add-bloc.nbBlocsLoca'),
        checked: true
      },
    ];
  }
  }

  show(obj) {
    return (this.headers.find(h => h.name === obj)).checked;
  }

  showList() {
    const modalRef = this.modalSelectField.open(SelectFieldsColumnComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe(headers => {
      this.headers = headers;
    });
  }

  onTabAddLocaChange(tabSet: NgbTabset) {
    tabSet.select('equipementsConduite');
  }

  changeTabLoca(tabSet: NgbTabset) {
    this.tabSet = tabSet;
  }

  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < '0'.charCodeAt(0) || charCode > '9'.charCodeAt(0))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  arrondirDistance(event) {
    if ((event.key >= '0' && event.key <= '9')) {
      if (this.distanceInterRang.value) {
        this.distanceInterRang.setValue(Math.round(this.distanceInterRang.value * 100) / 100);
      }
      if (this.distanceIntraRang.value) {
        this.distanceIntraRang.setValue(Math.round(this.distanceIntraRang.value * 100) / 100);
      }
    }
  }

  verifSurfacePlantee() {
    if(this.surfaceEngagee && this.surfacePlantee && this.surfaceEngagee.value <= this.surfacePlantee.value)return false;
    else return true;
  }


  loadProducts() {

    // Récupérer l'ID du CDCs sélectionné
    let idsCdcs: number[] = [this.cahier.value.id];   
    this.loadProductsByCdc(idsCdcs);

  }

  loadProduitsDestination() {
    this.produitServiceDec.getProduitsByProduitOrigine(this.identifie.value.id).subscribe(produits => {
      
      this.controleFormDestination.disable();
      this.produitDestinations = produits;
      this.produitDestinations.push(this.identifie.value);
      this.destination.setValue(this.identifie.value);
      this.controleFormDestination.enable();
    });

  }


  /**
   * Charge les produits en fonction du cahier des charges selectionné
   */
  loadProductsByCdc(idsCdcs: number[]) {
    this.produitIdentifies = [];
    this.controleFormDestination.disable();

    // Récupérer les produits liés au CDC sélectionné
    this.produitServiceHab.getProduitsByCdcs(this.idOrganisme, idsCdcs).subscribe(produits => {
      this.produitIdentifies = produits;     
      this.controleFormDestination.enable();
    });

  }

  selectItem(newItem: Entreprise) {
    this.destinataire = newItem;
  }

}
