import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Cahier } from '../../models/cahier.model';
import { ActivitesService } from '../activites/activites.service';
import { Cache, ClearCache } from 'src/app/core/services/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class CahiersService {

  static cahiers: Cahier[];

  protected BASE_URL = '/api/habilitation';

  constructor(
    public readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly activitesService: ActivitesService
  ) { }

  static getCahiers(): Cahier[] {
    const cahiers = CahiersService.cahiers;
    if (cahiers === undefined) {
      throw new Error('service-cahiers.static-data.unavailable');
    }
    return cahiers;
  }

  @ClearCache
  clearCache() { }

  @Cache()
  public getCahiers(): Observable<Cahier[]> {
    return forkJoin([
      this.http.get<object[]>(`${this.BASE_URL}/private/cahiers`),
      this.activitesService.getActivites() // activites must be loaded before deserialisation
    ])
      .pipe(
        map(([cahiers, activites]) => this.mapper.deserializeArray(cahiers, Cahier, { activites })),
        tap(cahiers => { CahiersService.cahiers = cahiers; })
      );
  }
  @Cache()
  public getCahiersByHabilitation(
    idIntervenant: number,
    idOrganisme: number,
    codeStatut: string,
    codeHabilitation: string): Observable<Cahier[]> {
    return forkJoin([
      // tslint:disable-next-line:max-line-length
      this.http.get<object[]>(`/api/declaration/private/habilitation/${codeHabilitation}/cahiers?intervenant=${idIntervenant}&organisme=${idOrganisme}&statutHabilitation=${codeStatut}`),
      this.activitesService.getActivites() // activites must be loaded before deserialisation
    ])
      .pipe(
        map(([cahiers, activites]) => this.mapper.deserializeArray(cahiers, Cahier, { activites })),
        tap(cahiers => { CahiersService.cahiers = cahiers; })
      );
  }

  public getCahiersByProduits(idProduits: number[]): Observable<Cahier[]> {
    return forkJoin([
      this.http.get<object[]>(`${this.BASE_URL}/private/cahiers/produits/${idProduits}`),
      this.activitesService.getActivites() // activites must be loaded before deserialisation
    ])
      .pipe(
        // map(([cahiers]) => this.mapper.deserializeArray(cahiers, Cahier))
        map(([cahiers, activites]) => this.mapper.deserializeArray(cahiers, Cahier, { activites })),
        tap(cahiers => { CahiersService.cahiers = cahiers; })
      );
  }
}
