import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { pull } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { Espece } from 'src/app/data/declaration/models/espece.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { EcoulementsService } from 'src/app/data/declaration/services/ecoulements/ecoulements.service';
import { EspeceService } from 'src/app/data/declaration/services/parcellaire/espece.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { ContactReferent } from 'src/app/data/intervenant/models/contact-referent.model';
import { EntrepriseReferent } from 'src/app/data/intervenant/models/entreprise-referent.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { References } from 'src/app/data/intervenant/models/references.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { SuiviEcoulement } from 'src/app/data/suivis/models/suivi-ecoulement.model';
import { SuivisService } from 'src/app/data/suivis/services/suivis.service';
import { EmailsHelper } from 'src/app/shared/helpers/emails.helper';
import { Email } from 'src/app/shared/interfaces/email.interface';
import { NavigatorExtended } from 'src/app/shared/interfaces/navigator-extended.interface';
import { EstimationEcoulementComponent } from './modals/estimation-ecoulement/estimation-ecoulement.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-suivi-ecoulements',
  templateUrl: './suivi-ecoulements.component.html',
  styleUrls: ['./suivi-ecoulements.component.scss']
})
export class SuiviEcoulementsComponent implements OnInit {
  
  // Routes
  public static routes: Routes = [{
    path: '', component: SuiviEcoulementsComponent, data: {
      role: 'ORGANISME',
      domaines: ['ANPP'],
      menu: {
        libelle: 'menu.suivi-ecoulement',
        icon: 'cogs'
      }
    }
  }];  

    isAnpp: Boolean;
    public references: References;
    mois: number[] = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8]
    numMois: number = 0;
    campagne: Campagne;
    campagnes: Campagne[];
    especes: Espece[];
    entreprises: Entreprise[];
    entreprisesReferents: EntrepriseReferent[];
    public entreprisesSelectionnees: Array<number> = [];
    suivis: SuiviEcoulement[];
    suivisFilter: SuiviEcoulement[];
    pourcentMisAJour = 0;
    totalNbEntreprises = 0;
    entreprisesSansDroit: number[];

    public etats: Array<{ code: number, libelle: string }> = [
      { code: 1, libelle: 'page.suivi.suivi-ecoulements.manquantes' },
      { code: 2, libelle: 'page.suivi.suivi-ecoulements.recues' }
    ];
    public filtersForm: FormGroup;    
    public showFilters: boolean = false;

    // getters privés
    private get _campagne(): AbstractControl { return this.filtersForm.get('campagne'); }
    private get _mois(): AbstractControl { return this.filtersForm.get('mois'); }    
    private get _espece(): AbstractControl { return this.filtersForm.get('espece'); }
    private get _type(): AbstractControl { return this.filtersForm.get('type'); }
    private get _terroir(): AbstractControl { return this.filtersForm.get('terroir'); }
    private get _ecoulementRecu(): AbstractControl { return this.filtersForm.get('ecoulementRecu'); }

    get campagneSel() { return this.filtersForm.get('selectionCampagne'); }
    get moisSel() { return this.filtersForm.get('selectionMois'); }
  
    

  constructor(
    private suivisService: SuivisService,
    private readonly campagneService: RefCampagnesService,
    private readonly especeService: EspeceService,
    private readonly ecoulementService: EcoulementsService,
    private readonly entrepriseService: EntreprisesService,
    private referencesService: ReferencesService,
    private toastrService: ToastrService,
    private router: Router,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private declarationsService: DeclarationsService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly autService: AuthService
  ) { }

  ngOnInit() {
    this.suivisService.setActiveTabId('ecoulements');
    this.isAnpp = this.autService.isAnpp();

    // Initialiser le formulaire de recherche avancée
    this.filtersForm = this.formBuilder.group({
      campagne: [-1],
      espece: [-1],
      type: [-1],
      terroir: [-1],
      mois: [-1],
      ecoulementRecu: [-1]
    });
        
    let today = new Date();
    this.numMois = today.getMonth() + 1; // (today.getDate() < 25) ? (today.getMonth() + 1)  : (today.getMonth() + 2);
    if (!this.mois.indexOf(this.numMois)){
      this.numMois = this.mois[0];
    }
    const leMois = this.mois.find(m => m == this.numMois);
    this._mois.setValue(leMois);

    forkJoin([
      this.campagneService.getCampagneAll(),
      this.especeService.getEspeces(),
      this.entrepriseService.getEntreprisesReferents(),
      //this.entrepriseService.getEntreprises(),
      this.entrepriseService.getEntreprisesBySousRefType(["PI_DEFAUT", "GP_DEFAUT"]),
      this.campagneService.getCampagneEnCours(),
      this.referencesService.getReferences()]).pipe(this.loaderService.operator())
      .subscribe(([campagnes, especes, entreprisesReferents, entreprises, campagneEnCours, references]) => {
        this.campagne = campagneEnCours;
        forkJoin([
          this.ecoulementService.getSuivisEcoulements(campagneEnCours.id, this.numMois),
          this.isAnpp ? this.campagneService.getIdEntrepriseDroitEcoulementFalse(campagneEnCours.id) : of(null)
        ]).pipe(this.loaderService.operator())
        .subscribe(([retourSuivis, entrepriseSansDroit]) => {
          this.campagnes = campagnes.sort((a, b) => {
            if (a && b && a.annee > b.annee) return -1;
            if (a && b && a.annee < b.annee) return 1;
            return 0;
          });
          this.entreprises = entreprises;
          this.entreprisesReferents = entreprisesReferents;
          this.references = references;
          this.especes = especes;
          const camp = this.campagnes.find(c => c.id === this.campagne.id);
          this._campagne.setValue(camp);
          this.suivis = [];
          this.entreprisesSansDroit = entrepriseSansDroit;
          this.creationDatas(retourSuivis);
  
          this.references = references;
          this.suivisFilter = this.suivis;
          this.calculerPourcentages();
        })
      })
  }

  private creationDatas(retourSuivis:SuiviEcoulement[]){
   this. entreprises.forEach(e => {
    if(!this.entreprisesSansDroit ||(this.entreprisesSansDroit&&!this.entreprisesSansDroit.includes(e.id))){
      let suivisEntreprise = retourSuivis.filter(rs => rs.idEntreprise === e.id);
      if (suivisEntreprise == undefined || suivisEntreprise.length===0) {
        // une ligne vide pour l'entreprise (pas de suivi trouvé)
        let suivi = new SuiviEcoulement();
          suivi.entreprise = e;
          suivi.idEntreprise = e.id;
          this.suivis.push(suivi);
      } else {
        suivisEntreprise.forEach(theSuivi => {
          // une ligne par especes trouvées
          let suivi = new SuiviEcoulement();
          suivi.entreprise = e;
          suivi.idEntreprise = e.id;
          suivi.qteBio = theSuivi.qteBio;
          suivi.qteTotale = theSuivi.qteTotale;
          suivi.dateDeclaration = theSuivi.dateDeclaration;
          suivi.idDeclaration = theSuivi.idDeclaration;
          suivi.codeEspece = theSuivi.codeEspece;
          this.suivis.push(suivi);
        });          
      }
    }
      
      this.suivis.sort(
        // tri par espece (Pomme, poire) puis raison sociale
        (n1,n2)=>{
          if (n1.codeEspece === undefined && n2.codeEspece === undefined ) return n1.entreprise.raisonSociale.toLowerCase().localeCompare(n2.entreprise.raisonSociale.toLowerCase());
          if (n1.codeEspece && n2.codeEspece === undefined ) return -1;
          if (n1.codeEspece === undefined && n2.codeEspece ) return 1;
          if (n1.codeEspece === n2.codeEspece ) return n1.entreprise.raisonSociale.toLowerCase().localeCompare(n2.entreprise.raisonSociale.toLowerCase());
          if (n1.codeEspece === "POMME" && n2.codeEspece === 'POIRE' ) return -1;
          if (n1.codeEspece === "POIRE" && n2.codeEspece === 'POMME' ) return 1;
           else return 0; 
       })
    });
  }

  loadData(){
    this.ecoulementService.getSuivisEcoulements(this.campagne.id, this.numMois).subscribe(retourSuivis=> {
      this.suivis = [];
      this.creationDatas(retourSuivis);
      retourSuivis.forEach(rs => {       
      });
      this.suivis = this.suivis;
      this.suivisFilter = this.suivis;
      this.calculerPourcentages();
    });
  }


  onCampagneChange() {
    this.campagne = this.filtersForm.get('campagne').value as Campagne;

    this.loadData();
  }

  onMoisChange() {
    this.numMois = this.filtersForm.get('mois').value as number;

    this.loadData();
  }  

  getLibelleMois(numMois: number): string{
    let date = new Date(2022, numMois-1, 15);
    return date.toLocaleString(undefined,  { month: 'long' }); 
  }


  isFirstMonth() {
    // est-ce le premier mois de la liste
    return (this.mois.indexOf(this.numMois) <= 0);
  }
  isLastMonth() {
    // est-ce le dernier mois de la liste
    return (this.mois.indexOf(this.numMois) >= this.mois.length - 1);
  }


  public onFilterClick(): void {
    this.showFilters = !this.showFilters;
  }



  public onRelance(idEntreprise: number): void {
    const referentsEmails = this.getReferentsEmails(idEntreprise);
    if (referentsEmails != null && referentsEmails.length > 0) {
      EmailsHelper.envoyerEMail(<Email>{ to: referentsEmails });
    } else {
      this.toastrService.error(this.translateService.instant('page.suivi.suivi-ecoulements.emails-copie-erreur'));
    }
  }  

  private getReferentsEmails(idEntreprise: number): Array<string> {
    const entreprise = this.entreprisesReferents.find((entreprise: EntrepriseReferent) => entreprise.id === idEntreprise);
    return (entreprise && entreprise.referentEcoulementList.length > 0)
      ? entreprise.referentEcoulementList.map((contact: ContactReferent) => contact.mail)
      : [];
  }

  calculerPourcentages() {
    this.totalNbEntreprises =  (new Set(this.suivisFilter.map(ligne => ligne.idEntreprise))).size;
    if (this.suivisFilter && this.suivisFilter.length > 0){  
      let nbSuivisByEntreprise =  (new Set(this.suivisFilter.filter(s=>s.dateDeclaration).map(ligne => ligne.idEntreprise))).size;
      this.pourcentMisAJour = nbSuivisByEntreprise / this.totalNbEntreprises * 100;
    } else {
      this.pourcentMisAJour = undefined;
    }
  };

  public filter(): void {
    const campagne = this._campagne.value;
    const espece = this._espece.value;
    const type = this._type.value;
    const terroir = this._terroir.value;
    const ecoulementRecu = this._ecoulementRecu.value;

    this.suivisFilter = this.suivis.filter((declaration: SuiviEcoulement) => {
      const entreprise = this.entreprisesReferents.find((entreprise: EntrepriseReferent) => entreprise.id == declaration.idEntreprise);

      if (espece != "-1" && declaration.codeEspece != espece) {
         return false;
      }
      if (type != -1 && (entreprise == null || entreprise.sousType.id != type)) {
        return false;
      }
      if (terroir != -1 && (entreprise == null || terroir.indexOf(entreprise.departement) == -1)) {
        return false;
      }
      if ((ecoulementRecu == 1 && declaration.dateDeclaration) || (ecoulementRecu == 2 && !declaration.dateDeclaration)) {
        return false;
      }
      

      return true;
    });
    this.calculerPourcentages();
  }

  public onOpenEstimations(): void {
    this.modal.open(EstimationEcoulementComponent, { backdrop: 'static', windowClass: 'app-modal-1001px' });
  }

  public onGoToDeclarationEcoulement(idEntreprise: number): void {
    this.declarationsService.entrepriseSelectionnee = <Entreprise>{ 
      id: idEntreprise,
      raisonSociale: this.getLibelleEntreprise(idEntreprise)
    };
    this.router.navigate([`/main/declarations-pp/ecoulements/${idEntreprise}`]);
  }

  public getLibelleEntreprise(idEntreprise: number): string {
    const entreprise = this.entreprisesReferents.find((entreprise: EntrepriseReferent) => entreprise.id == idEntreprise);
    return entreprise ? entreprise.raisonSociale : '';
  }


  public onCheckAllDeclarations(event: any): void {
    this.entreprisesSelectionnees = [];
    this.suivisFilter.forEach((declaration: SuiviEcoulement) => {
      declaration.estCochee = event.target.checked;
      if (event.target.checked && !this.entreprisesSelectionnees.includes(declaration.idEntreprise)) {
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
      }
    });
  }

  public onCheckDeclaration(event: Event, declaration: SuiviEcoulement): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    // Met à jour l'état 'estCochee' de la déclaration selon l'état de la case cochée
    declaration.estCochee = isChecked;

    // Vérifie si la case est cochée et l'entreprise n'est pas encore dans la liste
    if (isChecked && !this.entreprisesSelectionnees.includes(declaration.idEntreprise)) {
        // Ajoute l'entreprise sélectionnée à la liste
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
    } 
    // Sinon, si la case est décochée et que l'entreprise est dans la liste
    else if (!isChecked) {
        // Retire l'entreprise de la liste
        pull(this.entreprisesSelectionnees, declaration.idEntreprise);
    }
}

  public onRelanceGroupee(): void {
    let referentsEmails: Array<string> = [];
    this.entreprisesSelectionnees.forEach((idEntreprise: number) => 
      referentsEmails = referentsEmails.concat(this.getReferentsEmails(idEntreprise))
    );
    if (referentsEmails != null && referentsEmails.length > 0) {
      (navigator as NavigatorExtended).clipboard.writeText(referentsEmails.join(';')).then(() => {
        this.toastrService.success(
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-succes'),
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-titre'),
          { timeOut: 10 * 1000 }
        );
      }
      ).catch(e => console.error(e));
    } else {
      this.toastrService.error(this.translateService.instant('page.suivi.suivi-ecoulements.emails-copie-erreur'));
    }
  }

  /**
   * copie dans le presse papier des emails des contacts référents de la ligne sélectionnée
   * @param suivi
   */
   onGetEmails(suivi: SuiviEcoulement) {
    const referentsEmails = this.getReferentsEmails(suivi.idEntreprise);
    if (referentsEmails != null) {
      (navigator as NavigatorExtended).clipboard.writeText(referentsEmails.join(';')).then(() => {
        this.toastrService.success(
          this.translateService.instant('page.suivi.suivi-ecoulements.emails-copies-succes'),
          this.translateService.instant('page.suivi.suivi-ecoulements.emails-copies-titre'),
          { timeOut: 10 * 1000 }
        );
      }
      ).catch(e => console.error(e));
    } else {
      this.toastrService.warning(
        this.translateService.instant('page.suivi.suivi-ecoulements.emails-copies-erreur'),
        this.translateService.instant('page.suivi.suivi-ecoulements.emails-copies-titre'),
        { timeOut: 10 * 1000 }
      );
    }
  }  
}
